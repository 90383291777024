import React, { Component, Suspense } from 'react';
import './app.scss';
import './loading.scss';
import {
  IBasic,
  IBasic_Extended,
  IData,
  IFooter_Extended,
  IPrefooter_Extended, IQualityRetouch,
  ISection1_Extended,
  ISection2_Extended,
  ISection3_Extended,
  ISection4_Extended,
  ISection5_Extended,
  ISection6_Extended,
  ISection7_Extended
} from '../../interface';
import { data } from '../../data/data';
import Video from '../video';
import videoPromo from '../../assets/video/promo.mp4';
import video2 from '../../assets/video/video2.mp4';
import video4 from '../../assets/video/video4.mp4';
import videoProgram from '../../assets/video/video-program.mp4';

const Section1 = React.lazy(() => import('../section1'));
const Section2 = React.lazy(() => import('../section2'));
const Section3 = React.lazy(() => import('../section3'));
const QualityRetouch = React.lazy(() => import('../quality-retouch'));
const Section4 = React.lazy(() => import('../section4'));
const Section5 = React.lazy(() => import('../section5'));
const Section6 = React.lazy(() => import('../section6'));
const Section7 = React.lazy(() => import('../section7'));
const Footer = React.lazy(() => import('../footer'));
const Prefooter = React.lazy(() => import('../prefooter'));

export default class App extends Component {
  private refSection1: React.RefObject<HTMLElement>;
  private refSection2: React.RefObject<HTMLElement>;
  private refSection3: React.RefObject<HTMLElement>;
  private refSection4: React.RefObject<HTMLElement>;
  private refSection5: React.RefObject<HTMLElement>;
  private refSection6: React.RefObject<HTMLElement>;
  private refSection7: React.RefObject<HTMLElement>;
  private refImg: React.RefObject<HTMLElement>;
  private refSectionQuality: React.RefObject<HTMLElement>;
  private refQuality: React.RefObject<HTMLElement>;
  private refQualityRetouch: React.RefObject<HTMLElement>;
  private targetElement: HTMLElement;

  constructor(props: any) {
    super(props);
    this.refSection1 = React.createRef();
    this.refSection2 = React.createRef();
    this.refSection3 = React.createRef();
    this.refSection4 = React.createRef();
    this.refSection5 = React.createRef();
    this.refSection6 = React.createRef();
    this.refSection7 = React.createRef();
    this.refImg = React.createRef();
    this.refSectionQuality = React.createRef();
    this.refQuality = React.createRef();
    this.refQualityRetouch = React.createRef();
    this.targetElement = document.createElement('div');
  }

  getTheme = () => {
    let theme: string | null = localStorage.getItem('easyTheme');
    return theme ? JSON.parse(theme) : 'black';
  };

  getLang = () => {
    let lang: string | null = localStorage.getItem('easyLang');
    return lang ? JSON.parse(lang) : 'ru';
  };

  state = {
    startAnimationY: 9999,
    block: false,
    ladyAnimation: false,
    loaded: false,
    blocksActive: Array(13).fill(false),
    goingUp: false,
    section2TitleAppear: false,
    section2DescAppear: false,
    section2BlocksAppear: false,
    section3TitleAppear: false,
    section3BlocksAppear: false,
    section4TitleAppear: false,
    section4BlocksAppear: false,
    section5TitleAppear: false,
    section5BlocksAppear: false,
    section6TitleAppear: false,
    section6BlocksAppear: false,
    menuItemSection5Active: 0,
    menuFirstLoad: true,
    menuOn: false,
    theme: this.getTheme(),
    lang: this.getLang(),
    videoPromo: false,
    videoSection2: false,
    videoSection4: false,
    videoSection5: false,
    isSignCourseFormActive: false,
    formCallback: {
      name: '',
      phone: '',
      politics: false
    },
    formSignUpCourse: {
      name: '',
      phone: '',
      politics: false
    }
  };

  ticking = false;

  currentScroll = 0;

  sectionsHeights: number[] = [];

  changeTheme = () => {
    const theme = this.state.theme === 'black' ? 'white' : 'black';
    localStorage.setItem('easyTheme', JSON.stringify(theme));
    this.setState({ theme: theme });
  };

  changeHTMLBodyClass = () => {
    const html = document.getElementsByTagName('html') as HTMLCollectionOf<HTMLElement>;
    const body = document.getElementsByTagName('body') as HTMLCollectionOf<HTMLElement>;
    if (this.state.theme === 'white') {
      if (html[0].classList.contains('black')) html[0].classList.remove('black');
      if (!html[0].classList.contains('white')) html[0].classList.add('white');
      if (body[0].classList.contains('black')) body[0].classList.remove('black');
      if (!body[0].classList.contains('white')) body[0].classList.add('white');
    } else {
      if (html[0].classList.contains('white')) html[0].classList.remove('white');
      if (!html[0].classList.contains('black')) html[0].classList.add('black');
      if (body[0].classList.contains('white')) body[0].classList.remove('white');
      if (!body[0].classList.contains('black')) body[0].classList.add('black');
    }
  };


  changeLang = (lang: string) => {
    localStorage.setItem('easyLang', JSON.stringify(lang));
    this.setState({ lang: lang });
  };

  setSectionHeights = () => {
    const section1 = this.refSection1.current as HTMLElement;
    const section2 = this.refSection2.current as HTMLElement;
    const quality = this.refQualityRetouch.current as HTMLElement;
    const section3 = this.refSection3.current as HTMLElement;
    const section4 = this.refSection4.current as HTMLElement;
    const section5 = this.refSection5.current as HTMLElement;
    if (
      !section1 || !section2 || !quality || !section3 || !section4 || !section5
    ) {
      setTimeout(() => {
        this.setSectionHeights();
      }, 0);
    } else {
      const section1Styles = document.defaultView?.getComputedStyle(section1, null);

      const section2Styles = document.defaultView?.getComputedStyle(section2, null);

      const qualityStyles = document.defaultView?.getComputedStyle(quality, null);

      const section3Styles = document.defaultView?.getComputedStyle(section3, null);

      const section4Styles = document.defaultView?.getComputedStyle(section4, null);

      const section5Styles = document.defaultView?.getComputedStyle(section5, null);

      if (section1Styles && section2Styles && qualityStyles && section3Styles && section4Styles && section5Styles) {
        const h1s = Number(parseInt(section1Styles.height));
        const h2s = h1s + parseInt(section2Styles.height);
        const qual = h2s + parseInt(qualityStyles.height);
        const h3s = qual + parseInt(section3Styles.height);
        const h4s = h3s + parseInt(section4Styles.height);
        const h5s = h4s + parseInt(section5Styles.height);
        this.sectionsHeights = [h1s, h2s, qual, h3s, h4s, h5s];
      }
      const section1Top = section1.getBoundingClientRect().top;
      const section2Bottom = section2.getBoundingClientRect().bottom;
      const endBeforeQuality = section1Top && section2Bottom ? section2Bottom - section1Top : 1800;
      this.sectionsHeights.push(endBeforeQuality);
      window.removeEventListener('load', this.setSectionHeights);
      this.setState({ loaded: true });
    }
  };

  setInstaToken = () => {

  }

  componentDidMount() {
    window.addEventListener('load', this.setSectionHeights);
    // window.addEventListener("scroll", this.handleScroll,{passive: true});
    this.targetElement = document.getElementById('header-menu') as HTMLElement;
    this.changeHTMLBodyClass();
    this.setInstaToken();
    const fromOtherPages = localStorage.getItem('section5');
    if (fromOtherPages !== null) {
      localStorage.removeItem('section5');
      this.setState({ menuItemSection5Active: Number(fromOtherPages) });
    }
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any) {
    if (!this.state.loaded) {
      this.setSectionHeights();
    }
    this.changeHTMLBodyClass();
  }

  componentWillUnmount() {
    // window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener('touchmove', this.preventDefault);
  }

  preventDefault(e: Event) {
    e.preventDefault();
  }

  overlayFadeInClass = () => (
    this.state.menuOn ||
    this.state.videoPromo ||
    this.state.videoSection2 ||
    this.state.videoSection4 ||
    this.state.videoSection5 ||
    this.state.isSignCourseFormActive
  ) ? 'overlay-fadeIn' : '';

  toggleScrollAbility = (): void => {
    if (
      this.state.menuOn ||
      this.state.videoPromo ||
      this.state.videoSection2 ||
      this.state.videoSection4 ||
      this.state.videoSection5
    ) {
      document.body.style.overflowY = 'visible';
      window.removeEventListener('touchmove', this.preventDefault);
    } else {
      document.body.style.overflowY = 'hidden';
      window.addEventListener('touchmove', this.preventDefault, { passive: false });
    }
  };

  toggleVideoPromo = (): void => {
    this.toggleScrollAbility();
    this.setState({
      videoPromo: !this.state.videoPromo,
    });
  };

  toggleVideoSection2 = (): void => {
    this.toggleScrollAbility();
    this.setState({
      videoSection2: !this.state.videoSection2,
    });
  };

  toggleVideoSection4 = (): void => {
    this.toggleScrollAbility();
    this.setState({
      videoSection4: !this.state.videoSection4,
    });
  };

  toggleVideoSection5 = (): void => {
    this.toggleScrollAbility();
    this.setState({
      videoSection5: !this.state.videoSection5,
    });
  };

  toggleMenu = (): void => {
    this.toggleScrollAbility();
    if (this.state.menuFirstLoad) {
      this.setState({
        menuFirstLoad: false,
        menuOn: !this.state.menuOn,
      });
    } else {
      this.setState({
        menuOn: !this.state.menuOn,
      });
    }

  };

  getData = (): IData => {
    switch (this.state.lang) {
      case 'ru':
        return data.ru;
      case 'en':
        return data.en;
      default:
        return data.ru;
    }
  };

  getDataSection = (section: string) => {
    const data = this.getData();
    switch (section) {
      case 'basic':
        return { basic: data.basic as IBasic };
      case 'section1':
        return { basic: data.basic, section1: data.section1 };
      case 'section2':
        return { basic: data.basic, section2: data.section2 };
      case 'qualityRetouch':
        return data.qualityRetouch;
      case 'section3':
        return { basic: data.basic, section3: data.section3 };
      case 'section4':
        return { basic: data.basic, section4: data.section4 };
      case 'section5':
        return { basic: data.basic, section5: data.section5 };
      case 'section6':
        return { basic: data.basic, section6: data.section6 };
      case 'section7':
        return { basic: data.basic, section7: data.section7 };
      case 'prefooter':
        return { basic: data.basic, prefooter: data.prefooter };
      case 'footer':
        return { basic: data.basic, footer: data.footer };
    }
  };

  handleOverlayClick = (e?: any) => {
    // @ts-ignore
    if ((e?.target.textContent === this.getDataSection('basic')?.basic.menu.menuTop[3].title)) {
      this.setState({ menuItemSection5Active: 2 });
    }
    // @ts-ignore
    if ((e?.target.textContent === this.getDataSection('basic')?.basic.menu.menuTop[2].title)) {
      this.setState({ menuItemSection5Active: 1 });
    }
    if (this.state.menuOn) this.toggleMenu();
    if (this.state.videoPromo) this.toggleVideoPromo();
    if (this.state.videoSection2) this.toggleVideoSection2();
    if (this.state.videoSection4) this.toggleVideoSection4();
    if (this.state.videoSection5) this.toggleVideoSection5();
    if (this.state.isSignCourseFormActive) this.setIsSignCourseFormActive(false);
  };

  activateFormSubmitButton = (
    form = 'formCallback',
    name = this.state.formCallback.name,
    phone = this.state.formCallback.phone,
    politics = this.state.formCallback.politics): void => {
    switch (form) {
      case 'formCallback':
        this.setState({
          formCallback: {
            name: name,
            phone: phone,
            politics: politics
          }
        });
        break;
      case 'formSignUpCourse':
        this.setState({
          formSignUpCourse: {
            name: name,
            phone: phone,
            politics: politics
          }
        });
        break;
      default:
        this.setState({
          formCallback: {
            name: name,
            phone: phone,
            politics: politics
          }
        });
    }
  };

  setIsSignCourseFormActive = (status: boolean): void => {
    this.setState({ isSignCourseFormActive: status });
  };

  handlePause = (ref: React.RefObject<HTMLVideoElement>) => {
    if (ref.current !== null) {
      ref.current.pause();
    }
  };

  handlePlay = (ref: React.RefObject<HTMLVideoElement>) => {
    if (ref.current !== null) {
      ref.current.play();
    }
  };

  browserSupport = (this.getDataSection('basic') as IBasic_Extended).basic.browserSupport;

  render() {
    return (
      <>
        <div id="overlay"
             className={`overlay ${this.overlayFadeInClass()}`}
             onClick={this.handleOverlayClick}>
        </div>
        <main className={`easy-main ${this.state.theme}`}>
          <Suspense fallback={<div className="loadingio-spinner-dual-ring-wfiizqs7edj">
            <div className="ldio-6lbj7tk3vqw">
              <div></div>
              <div>
                <div></div>
              </div>
            </div>
          </div>}>

            <Video
              videoTitle="promo"
              video={videoPromo}
              browserSupport={this.browserSupport}
              isVideo={this.state.videoPromo}
              handlePause={this.handlePause}
            />

            <Video
              videoTitle="results"
              video={video2}
              browserSupport={this.browserSupport}
              isVideo={this.state.videoSection2}
              handlePause={this.handlePause}
            />

            <Video
              videoTitle="What you need for the start"
              video={video4}
              browserSupport={this.browserSupport}
              isVideo={this.state.videoSection4}
              handlePause={this.handlePause}
            />

            <Video
              videoTitle="course-overview"
              video={videoProgram}
              browserSupport={this.browserSupport}
              isVideo={this.state.videoSection5}
              handlePause={this.handlePause}
            />

            <Section1
              refSection={this.refSection1}
              data={this.getDataSection('section1') as ISection1_Extended}
              showMenu={this.toggleMenu}
              menuOn={this.state.menuOn}
              menuFirstLoad={this.state.menuFirstLoad}
              changeTheme={this.changeTheme}
              changeLang={this.changeLang}
              lang={this.state.lang}
              theme={this.state.theme}
              showVideoPopup={this.toggleVideoPromo}
              isVideoPromo={this.state.videoPromo}
              isVideoSection2={this.state.videoSection2}
              activateFormSubmit={this.activateFormSubmitButton}
              formCallback={this.state.formCallback}
              formSignUpCourse={this.state.formSignUpCourse}
              isCallbackActive={this.state.isSignCourseFormActive}
              setIsCallbackActive={this.setIsSignCourseFormActive}
              handlePause={this.handlePause}
              handlePlay={this.handlePlay}
              exitMenu={this.handleOverlayClick}
            />

            <Section2
              refSection={this.refSection2}
              data={this.getDataSection('section2') as ISection2_Extended}
              lang={this.state.lang}
              theme={this.state.theme}
              handlePause={this.handlePause}
              section2TitleAppear={this.state.section2TitleAppear}
              section2DescAppear={this.state.section2DescAppear}
              section2BlocksAppear={this.state.section2BlocksAppear}
              blocksActive={this.state.blocksActive}
              showVideoPopupSection2={this.toggleVideoSection2}
              isVideoSection2={this.state.videoSection2}
            />

            <QualityRetouch
              data={this.getDataSection('qualityRetouch') as IQualityRetouch}
              theme={this.state.theme}
              refImg={this.refImg}
              refSectionQuality={this.refSectionQuality}
              refQualityRetouch={this.refQualityRetouch}
              refQuality={this.refQuality}
            />
            <Section3
              refSection={this.refSection3}
              data={this.getDataSection('section3') as ISection3_Extended}
              theme={this.state.theme}
              section3TitleAppear={this.state.section3TitleAppear}
              section3BlocksAppear={this.state.section3BlocksAppear}
            />

            <Section4
              refSection={this.refSection4}
              data={this.getDataSection('section4') as ISection4_Extended}
              theme={this.state.theme}
              handlePause={this.handlePause}
              section4TitleAppear={this.state.section4TitleAppear}
              section4BlocksAppear={this.state.section4BlocksAppear}
              showVideoPopupSection4={this.toggleVideoSection4}
              isVideoSection4={this.state.videoSection4}
            />

            <Section5
              refSection={this.refSection5}
              menuItemSection5Active={this.state.menuItemSection5Active}
              data={this.getDataSection('section5') as ISection5_Extended}
              theme={this.state.theme}
              handlePause={this.handlePause}
              section5TitleAppear={this.state.section5TitleAppear}
              section5BlocksAppear={this.state.section5BlocksAppear}
              showVideoPopupSection5={this.toggleVideoSection5}
              isVideoSection5={this.state.videoSection5}
            />

            <Section6
              refSection={this.refSection6}
              data={this.getDataSection('section6') as ISection6_Extended}
              theme={this.state.theme}
              section6TitleAppear={this.state.section6TitleAppear}
              section6BlocksAppear={this.state.section6BlocksAppear}
            />

            <Section7
              refSection={this.refSection7}
              data={this.getDataSection('section7') as ISection7_Extended}
              theme={this.state.theme}
            />

            <Prefooter
              data={this.getDataSection('prefooter') as IPrefooter_Extended}
              theme={this.state.theme}
            />

            <Footer
              data={this.getDataSection('footer') as IFooter_Extended}
              theme={this.state.theme}
            />

          </Suspense>
        </main>
      </>
    );
  }
}
