import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import './index.scss';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import OtherPage from "./components/other-page";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App/>}/>
      <Route path='*' element={<OtherPage url="not-found"/>} />
      <Route path="students-results" element={<OtherPage url="students-results"/>}/>
      <Route path="students-portfolio/retouchClub" element={<OtherPage url="students-portfolio" fromWhere="retouchClub"/>}/>
      <Route path="students-portfolio/*" element={<OtherPage url="students-portfolio"/>}/>
      <Route path="choose-tariff/fromTop" element={<OtherPage url="choose-tariff" fromWhere="top"/>}/>
      <Route path="choose-tariff/self" element={<OtherPage url="choose-tariff" tariff="self" fromWhere="tariffs"/>}/>
      <Route path="choose-tariff/selfPlus" element={<OtherPage url="choose-tariff" tariff="selfPlus" fromWhere="tariffs"/>}/>
      <Route path="choose-tariff/team" element={<OtherPage url="choose-tariff" tariff="team" fromWhere="tariffs"/>}/>
      <Route path="choose-tariff/vip" element={<OtherPage url="choose-tariff" tariff="vip" fromWhere="tariffs"/>}/>
      <Route path="choose-tariff/*" element={<OtherPage url="choose-tariff"/>}/>
      <Route path="payment-methods/*" element={<OtherPage url="payment-methods"/>}/>
      <Route path="privacy-policy/*" element={<OtherPage url="privacy-policy"/>}/>
      <Route path="public-contract/*" element={<OtherPage url="public-contract"/>}/>
    </Routes>
  </BrowserRouter>,
  document.getElementById('main')
);

