import React, {useEffect, useRef, useState} from 'react';
import {IVideo} from '../../interface';
import './video.scss'

import poster1 from '../../assets/img/videoPoster1.webp';
import poster2 from '../../assets/img/videoPoster2.webp';
import poster3 from '../../assets/img/videoPoster3.webp';
import poster4 from '../../assets/img/videoPoster4.webp';

const Video = (props: IVideo) => {
  const {isVideo, handlePause, videoTitle} = props;

  const videoRef = useRef<HTMLVideoElement>(null);

  const [isVideoOn, setIsVideoOn] = useState(isVideo);

  const toggleIsVideoOn = () => {
    setIsVideoOn(!isVideoOn);
  }

  useEffect(() => {
    if (!isVideo) {
      handlePause(videoRef);
      setIsVideoOn(false);
    }
  }, [isVideo, handlePause]);

  const toggleVideo = () => props.isVideo ? 'easy-video-show' : 'easy-video-hide';

  const btnHidden = isVideoOn ? 'hidden' : '';

  useEffect(() => {
    if (videoRef.current) {
      if (isVideoOn) {
        videoRef.current.play();
        videoRef.current.controls = true;
      } else {
        videoRef.current.pause();
        videoRef.current.controls = true;
      }
    }

  }, [isVideoOn]);

  let poster;

  switch (videoTitle) {
    case 'promo':
      poster = poster1;
      break;
    case 'results':
      poster = poster2;
      break;
    case 'What you need for the start':
      poster = poster3;
      break;
    case 'course-overview':
      poster = poster4;
      break;
    default:
      poster = poster1;
  }


  return (
    <div
      className={`easy-video-container ${toggleVideo()}`}
    >
      <video
        id={`${props.videoTitle}-video`}
        className={`easy-${props.videoTitle}-video`}
        loop
        controls
        playsInline
        disablePictureInPicture
        controlsList='nodownload noremoteplayback noplaybackrate'
        ref={videoRef}
        width='100%'
        poster={poster}
        onClick={toggleIsVideoOn}

      >
        <source src={props.video} type="video/mp4"/>
        {props.browserSupport}
      </video>
      <div
        className={`play-button ${btnHidden}`}
        onClick={(e) => {
          setIsVideoOn(true);
        }}
      >
      </div>
    </div>
  );
}

export default Video;


