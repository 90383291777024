import React from 'react';
import './not-found.scss';

const NotFound = () => {
  return (
    <div className="not-found">
      Not Found 404
      <p>
        Страница не найдена
      </p>
    </div>
  );
}

export default NotFound;
