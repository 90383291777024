import React from "react";
import './public-contract.scss';
import {useNavigate} from "react-router-dom";

const PublicContract = (props:any) => {
  const {data, lang, theme} = props;

  let navigate = useNavigate();
  const goHome = () => {
    navigate('/#main');
  }

  return (
    <div className="page-container">
      <div className="breadcrumbs">
        <a
          onClick={goHome}
          className={`bread-link ${theme}`}
        >{data.basic.main}</a> /
        <span>{data.publicContract.breadcrumb}</span>
      </div>
      <div className={`page-title ${theme}`}>
        {data.publicContract.title}
      </div>
      {lang === 'ru'
        ?
        <div className={`page-text ${theme}`}>

          <div className="text-right">
            Утверждено:<br/>
            Приказом директора ООО «Ретач клаб»<br/>
            Д.Д. Пучок<br/>
            № 1 от 26.04.2022<br/>
          </div>
          <br/>
          Если по какой-либо причине Вы не согласны с условиями настоящей Публичной Оферты на оказание услуг,
          Политикой Конфиденциальности, не используйте Интернет-ресурс easyschool.retouchclub.net
          и не авторизуйтесь на Платформе GetCourse.
          <br/><br/>
          <h2 className='text-center'>
            ПУБЛИЧНАЯ ОФЕРТА<br/>
            на оказание услуг<br/>
          </h2><br/>
          Минск, 26 апреля 2022 г.<br/><br/>

          ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ООО Ретач клаб» (далее — «Компания»), УНП 193589603, Республика Беларусь,
          публикует настоящую Публичную оферту на оказание услуг по предоставлению доступа к Курсу и консультационных услуг
          дистанционно (далее совместно и по отдельности именуемые — «Услуги»).<br/><br/>
          Настоящая Публичная оферта адресована любому лицу, осуществляющему доступ к Интернет-ресурсу
          easyschool.retouchclub.net (далее – Сайт) и представляет собой официальное предложение Компании
          заключить договор на оказание Услуг (далее – «Договор» или «Соглашение») на условиях,
          изложенных в настоящей Публичной оферте (далее – Оферта).<br/><br/>

          Настоящая Оферта вступает в силу с момента ее опубликования Компанией на Сайте и действует до момента отзыва Оферты Компанией.
          Компания оставляет за собой право внести изменения в условия настоящей Оферты и (или) отозвать Оферту
          в любой момент по своему усмотрению. В случае внесения Компанией изменений в Оферту,
          такие изменения вступают в силу с момента размещения измененного текста оферты в сети Интернет
          по адресу______________________, если иной срок вступления изменений в силу не определен дополнительно
          при таком размещении. При этом к отношениям сторон применяется редакция Оферты,
          размещенная на сайте Компании в момент акцепта оферты Пользователем (п.1.3).<br/><br/>
          Настоящая оферта составлена на русском языке.<br/><br/>
          В случае если вам меньше 18 лет или вы не достигли возраста совершеннолетия в соответствии
          с законодательством вашего государства, просим вас передать данную оферту вашим родителям или законным представителям.
          <br/><br/>
          <strong>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</strong><br/><br/>
          В целях Оферты нижеприведенные термины используются в следующих значениях:<br/>
          <strong>Акцепт Оферты</strong> — полное и безоговорочное принятие Оферты путем осуществления действий,
          указанных в п. 1.3. Оферты.<br/>
          <strong>Пользователь</strong> — совершеннолетнее лицо, осуществившее Акцепт Оферты.<br/>
          <strong>Договор</strong> — договор между Пользователем и Компанией на оказание Услуг на условиях,
          изложенных в Оферте, который заключается посредством Акцепта Оферты.<br/>
          <strong>Курс</strong> – Курс «Основы ретуши цифровых фотографий», размещенный на Платформе,
          состоящий из восемнадцати Произведений, объединенных единой темой, обладающих общей структурой повествования,
          включающих задания на выполнение практических работ. Исключительное право на Курс как на составное
          произведение принадлежит Компании. Краткое описание Курса содержится на Сайте.<br/>
          <strong>Произведения</strong> – аудиовизуальные произведения, исключительные права на которые принадлежат
          Компании и размещенные ею на Платформе в составе Курса.<br/>
          <strong>Пакет услуг</strong> - набор Услуг, предоставляемый Компанией по выбору Пользователя,
          включающих доступ к Курсу для целей онлайн-просмотра Произведений, входящих в его состав,
          и различный уровень консультативной поддержки Пользователя. Сведения о доступных Пакетах услуг содержатся на
          Сайте и являются неотъемлемой частью настоящей Оферты.<br/>
          <strong>Платформа</strong> – Платформа GetCourse, интернет-ресурс, расположенный в сети Интернет по
          адресу https://getcourse.ru, посредством которого Пользователю предоставляется доступ к Курсу.<br/>
          <strong>Электронная почта Компании</strong> - documents@retouchclub.com, адрес электронной почты Компании,
          используемый для переписки по вопросам исполнения и прекращения настоящего Договора или в связи с ним.<br/><br/>

          <strong>1. АКЦЕПТ И ПРЕДМЕТ ДОГОВОРА</strong><br/><br/>
          1.1. В соответствии с настоящим Договором Компания оказывает Пользователю Услуги по предоставлению доступа
          к Курсу для целей онлайн-просмотра Произведений, входящих в его состав, и консультационные услуги дистанционно
          согласно выбранному Пользователем Пакету услуг, а Пользователь обязуется оплатить такие Услуги.<br/>
          Оказание Услуг Компанией направленно только на удовлетворение познавательных потребностей граждан,
          не направлено на приобретение профессии, специальности, переподготовку и повышение квалификации руководящих
          работников, специалистов, рабочих (служащих).<br/><br/>
          1.2. Договор заключается посредством Акцепта Пользователем Оферты Компании.<br/><br/>
          1.3. Акцептом является оплата Пользователем выбранного им Пакета услуг.<br/>
          Акцепт является полным и безоговорочным. Если Пользователь не согласен с какими либо положениями Оферты,
          он не должен оплачивать Пакет услуг.<br/><br/>

          <strong>2. ПОРЯДОК И СРОКИ ОКАЗАНИЯ УСЛУГ</strong><br/><br/>
          2.1. На Сайте размещены описания Пакетов услуг, предоставляемых Компанией Пользователям, а также сведения об
          их стоимости. Описания Пакетов услуг являются неотъемлемой частью настоящей Оферты.<br/>
          Пользователь выбирает Пакет услуг и производит его оплату через Сайт (нажав кнопку «Оплатить» и перейдя на
          страницу системы приема платежей для внесения оплаты) либо сообщает о своем выборе Компании и получает
          ссылку на оплату по электронной почте. В указанных случаях оплата производится через
          платежную систему ________________ либо _______ Пользователь может также самостоятельно оплатить выбранный
          Пакет услуг на расчетный счет Компании.<br/><br/>
          Днем оплаты считается день зачисления денежных средств на расчетный счет Компании.<br/>
          2.2. В течение 3 (трех) рабочих дней со дня полной оплаты стоимости Пакета услуг Пользователь получает
          на адрес электронной почты, указанный им при оплате через платежную систему, либо дополнительно сообщенный
          Компании – при оплате через расчетный счет, ссылку для доступа и логин и пароль для авторизации на Платформе,
          посредством которой будут оказываться Услуги.<br/><br/>
          2.3. Дата начала оказания Услуг – день, когда Пользователь впервые авторизовался на Платформе.<br/>
          Период оказания услуг  - 6 месяцев.<br/>
          Дата окончания оказания Услуг – дата истечения шести месяцев со дня первой авторизации Пользователя на
          Платформе. Услуги могут быть оказаны досрочно.<br/><br/>
          2.4. В ходе оказания Услуг Пользователь получает:<br/>
          - поэтапный доступ к Произведениям Курса, т.е. возможность их онлайн-просмотра на весь период оказания услуг;<br/>
          - возможность получать консультацию по содержанию Произведений в общем чате на Платформе,
          тематической группе в Telegram с другими Пользователями;<br/>
          - иные консультационные услуги в соответствии с выбранным Пользователем Пакетом услуг.
          Консультационные услуги оказываются Компанией с использованием адреса электронной почты, номера телефона
          в мессенджере Telegram, сообщенными Пользователем, которые также используются для переписки по вопросам
          исполнения и прекращения настоящего Договора или в связи с ним.<br/><br/>
          2.5. Доступ к каждому следующему Произведению Курса предоставляется после выполнения задания,
          содержащегося в предыдущем Произведении, и направления выполненного задания Компании.
          Непредоставление ссылки для просмотра следующего Произведения по причине отсутствия выполненного задания
          по предыдущему Произведению не является нарушением обязательств со стороны Компании и не влечет возврата
          денежных средств Пользователю.<br/><br/>
          2.6. Если это предусмотрено соответствующим Пакетом Услуг по окончании изучения Пользователем Произведений.
          Пользователь вправе пройти итоговое тестирование и при положительных результатах получить
          Сертификат об успешном окончании Курса.<br/>
          Форма Сертификата утверждается Компанией.<br/><br/>
          2.7. Услуги, предусмотренные настоящим договором, оказываются Компанией исключительно лично Пользователю.<br/><br/>
          2.8. Компания вправе привлекать для оказания Услуг третьих лиц.<br/><br/>

          <strong>3. ПРАВА И ОБЯЗАННОСТИ СТОРОН</strong><br/><br/>
          3.1. Компания обязуется:<br/><br/>
          3.1.1. В сроки, установленные настоящим Договором, предоставить Пользователю, полностью оплатившему Пакет
          услуг, ссылку для доступа и логин и пароль к Личному кабинету (профилю), при условии соблюдения
          Пользователем обязанности, установленной п.3.3.1. Договора.<br/>
          3.1.2. Предоставлять доступ (ссылку) к следующему Произведению после получения выполненного задания
          по предыдущему Произведению;<br/>
          3.1.3. В случае отсутствия доступа к Произведениям Курса в связи с техническими неполадками
          Платформы Компания  обязана обеспечить  устранение неполадок либо предоставить альтернативный доступ к Произведениям.<br/>
          3.1.4. Осуществлять консультационную поддержку относительно предоставляемых Услуг, порядка и правил оплаты и
          заключения договора по Электронной почте Компании.<br/><br/>
          <strong>3.2. Компания вправе:</strong><br/><br/>
          3.2.1. В случае прекращения Договора по любым основаниям, удалить все предоставленные Пользователем данные
          на Платформе, личный кабинет (профиль) Пользователя, без возможности их восстановления, а также удалять
          Пользователя из общих чатов, тематических групп в Telegram.<br/>
          3.2.2. Удалять без объяснения причин размещенную Пользователем информацию.<br/>
          3.2.3. Приостановить оказание услуг в случае нарушения Пользователем встречных обязательств по Договору,
          и/или существенном нарушении Пользователем своих обязательств по Договору и сообщить об этом в электронном
          письме на адрес электронной почты Пользователя, сообщенный им при осуществлении платежей через Сайт
          либо дополнительно сообщенный после оплаты Пакета услуг на расчетный счет Компании либо в ходе исполнения Договора.<br/>
          3.2.4. Компания имеет иные права, предусмотренные настоящим Договором и применимым правом.<br/><br/>
          <strong>3.3. Пользователь обязуется:</strong><br/><br/>
          3.3.1. Предоставить Компании все сведения и данные, запрашиваемые на Сайте, Платформе или непосредственно
          Компанией, необходимые для оказания услуг. А также при оплате Пакета услуг непосредственно на расчетный
          счет Компании сообщить свой адрес электронной почты для направления логина и пароля, самостоятельно
          (без запроса Компании) направив соответствующее сообщение на Электронную почту Компании.<br/>
          3.3.2. Не осуществлять запись Произведений, их трансляцию, не сохранять их на свои устройства, не
          предоставлять и не распространять (публиковать, размещать на Интернет-сайтах, передавать или перепродавать
          третьим лицам, иным образом делать доступными иным лицам) в коммерческих или некоммерческих целях
          предоставляемые Компанией Пользователю информацию и материалы в рамках настоящего договора, не создавать
          на основе Курса и иной информации, полученной от Компании, свои информационные продукты с целью извлечения
          прибыли, а также использовать полученную в ходе оказания Услуг информацию каким-либо иным образом,
          кроме как для личного пользования.<br/>
          3.3.3. Не изменять каким бы то ни было способом программную часть Сайта, Платформы, не совершать любые
          действия, направленные на изменение функционирования и работоспособности Сайта, Платформы.<br/>
          3.3.4. Не размещать на Сайте, Платформе персональные данные третьих лиц.<br/>
          3.3.5. Не размещать на Сайте, Платформе рекламу, а также любую информацию, размещение, распространение
          которой запрещено или ограничено, в соответствии с законодательством Республики Беларусь, страны места
          нахождения/регистрации Пользователя и/или может влечь административную или уголовную ответственность
          и/или вынесение требований уполномоченным органом об устранении нарушений, ограничение доступа к интернет-ресурсу.<br/>
          3.3.6. Не передавать реквизиты доступа (ссылку, логин, пароль) для получения Услуг третьим лицам,
          не использовать Услуги совместно с третьими лицами.<br/>
          3.3.7. Хранить данные для использования Платформы (логин и пароль) в недоступном для любых третьих лиц месте.
          Пользователь несет полную ответственность и риски всех возможных негативных последствий, связанных с
          неисполнением настоящего правила, а также отвечает за все действия, совершенные от его имени на Платформе.
          Пользователь обязуется незамедлительно уведомить Компанию на Электронный адрес Компании о неправомерных
          действиях от его имени.<br/>
          3.3.8. Обеспечить за свой счет для целей получения Услуг без возмещения Компанией соответствующих затрат
          соответствие используемых Пользователем устройств, сетей, услуг связи требованиям, установленным на Сайте,
          Платформе либо в ином порядке сообщенным Компанией.<br/>
          3.3.9. Выполнять  иные обязанности, установленные настоящим Договором и применимым правом.<br/><br/>
          3.4. Пользователь вправе:<br/><br/>
          3.4.1. Требовать от Компании выполнения обязательств по настоящему Договору в установленные Договором сроки.<br/><br/>

          <strong>4. СТОИМОСТЬ УСЛУГ, ПОРЯДОК РАСЧЕТОВ, ОФОРМЛЕНИЕ АКТОВ ОБ ОКАЗАННЫХ УСЛУГАХ</strong><br/><br/>
          4.1. Стоимость Пакетов услуг указана на Сайте в белорусских рублях, в российских рублях, в Долларах США.
          Стоимость указана без НДС в связи с применением Компанией упрощенной системы налогообложения.<br/>
          Валюта договора и расчетов белорусские рубли - для резидентов Республики Беларусь;<br/>
          Валюта договора и расчетов российские рубли – для резидентов Российской Федерации;<br/>
          Валюта договора и расчетов Доллары США – для резидентов иных стран, кроме Республики Беларусь и Российской Федерации.<br/><br/>
          4.2. Сумма Договора равна стоимости выбранного Пользователем Пакета услуг.<br/><br/>
          4.3. Условия оплаты – предоплата 100%. Услуги считаются оплаченными с момента поступления полной стоимости
          Пакета услуг на расчетный счет Компании. После 100% оплаты стоимость Пакета услуг изменению не подлежит.
          При частичной оплате Пакета Услуг - Услуги считаются не оплаченными Пользователем и не оказываются Компанией.
          В случае непоступления оставшейся части оплаты за Пакет услуг в течение 10 рабочих дней с момента
          перечисления первой части платежа по нему действие Договора прекращается и вся полученная сумма оплаты,
          за исключением расходов Компании на ее перечисление, возвращается Пользователю.<br/><br/>
          4.4. Возврат денежных средств в суммах и по причинам, установленным настоящим Договором и применимым правом,
          осуществляются Компанией на те платежные реквизиты, с которых был осуществлен последний платеж Пользователя
          в адрес Компании, а если данные реквизиты по каким-либо причинам на момент совершения платежа Компанией
          использованы быть не могут - на любые другие известные Компании платежные реквизиты Пользователя.
          Если Пользователь желает получить платеж на какие-либо иные платежные реквизиты, он обязан предварительно
          уведомить об этом Компанию не позднее 4 календарных дней до момента совершения платежа посредством
          направления сообщения на Электронную почту Компании.<br/><br/>
          4.5. Банковские расходы по переводу – несет Пользователь, за исключением расходов
          платежных систем (посредников в расчетах).<br/><br/>
          4.6. Компания не осуществляет обработку персональных данных плательщиков, предоставляемых
          для проведения расчетов. Компания не хранит данные банковских карт плательщиков на своих ресурсах,
          включая серверы, облачные технологии и др.<br/><br/>
          4.7. Документом, подтверждающим оказание Услуг, является Акт об оказанных услугах.<br/><br/>
          Акт об оказанных услугах составляется каждой из Сторон единолично, без его направления на согласование и
          подписание другой Стороне.<br/>
          Датой оказания Услуг, указываемой в Акте, является более ранняя из дат:<br/>
          - дата направления Компанией по электронной почте Сертификата об успешном окончании курса
          (не для всех Пакетов Услуг);<br/>
          - дата окончания оказания Услуг, определенная согласно п.2.3. Договора.<br/><br/>
          4.8. В случае если Пользователь не воспользовался Пакетом услуг в любой мере
          (не осуществлял просмотр, не участвовал в обсуждениях, не задавал вопросы, не выполнял практические
          задания и проч.) Услуги считаются оказанными Компанией в полном объеме и надлежащим образом.<br/><br/>

          <strong>5. КАЧЕСТВО УСЛУГ</strong><br/><br/>
          5.1. Стороны соглашаются, что Услуги по Договору предоставляются на условиях «как есть»,
          и Компания не несет ответственности за соответствие качества Услуг целям, требованиям и /или ожиданиям
          Пользователя, также Компания не несет ответственности за нерегулярность в предоставлении Услуг, временные
          перебои в работе Платформы или отсутствие доступа к Сайту независимо от причин такой нерегулярности,
          перебоев или отсутствия доступа.<br/><br/>
          5.2. Невзирая на положения пункта 5.1. настоящего Договора Компания предпримет максимально возможные
          усилия для устранения каких-либо сбоев и ошибок в работе Сайта, Платформы в случае их возникновения,
          в максимально короткие сроки. В случае необходимости проведения работ по техническому обслуживанию
          или усовершенствованию Платформы, Сайта Компания вправе приостановить оказание Услуг.<br/><br/>
          5.3. По всем возникающим вопросам работы Платформы или Сайта Пользователь должен обращаться в службу
          технической поддержки на Сайте или путем отправки запроса в Компанию на Электронный адрес Компании.<br/><br/>
          5.4. Компания не предоставляет гарантий или обещаний, связанных с качеством, безопасностью и надежностью
          Услуг. Компания отказывается от всех подразумеваемых гарантий и заявлений, включая, среди прочего,
          любые гарантии товарной пригодности, соответствия любым целям, гарантий прав собственности, точности
          данных и ненарушения прав.<br/><br/>
          5.5. Пользователь использует любую информацию, доступ к которой Пользователь получил в ходе оказания
          Услуг по Договору, на свой страх и риск и самостоятельно несет ответственность за возможные последствия
          использования указанной информации<br/><br/>

          <strong>6. ОТВЕТСТВЕННОСТЬ СТОРОН</strong><br/><br/>
          6.1. Действия, совершенные с использованием логина и пароля Пользователя в Личном кабинете, считаются
          действиями самого Пользователя, и Пользователь несет за них полную ответственность.<br/><br/>
          6.2. Компания не несет ответственности за неиспользование Пользователем предоставленного ему доступа
          к Курсу и иных прав, входящих в Пакет услуг, в установленный срок оказания Услуг.
          Продление срока не предоставляется.<br/><br/>
          6.3. В случае привлечения Компании к ответственности за информацию, размещенную Пользователем,
          Компания вправе взыскать понесенные убытки, расходы (в том числе судебные), штрафы, пени, неустойки,
          компенсации морального вреда и вреда деловой репутации с соответствующего Пользователя.<br/><br/>
          6.4. Компания не несет ответственности за действия (бездействия) Пользователей в отношении друг друга,
          а также не несет ответственности перед Пользователями за неправомерные действия /бездействие третьих лиц.<br/><br/>
          6.5. Компания несет ответственность перед Пользователем только в виде возмещения реального ущерба.
          Упущенная выгода возмещению не подлежит. Общий размер ответственности Компании перед Пользователем
          ограничен стоимостью Пакета услуг, оплаченной Пользователем.<br/><br/>
          6.6. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по
          настоящему Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы либо
          событий чрезвычайного характера, которые возникли после заключения Договора, и которые Стороны не
          могли ни предвидеть, ни предотвратить разумными мерами, к которым в том числе относятся и действия и
          акты органов власти, препятствующие или делающие невозможным исполнение обязательств, установленных Договором.
          Если такие обстоятельства будут длиться более 6 месяцев, каждая из Сторон вправе отказаться от исполнения
          настоящего Договора без возмещения возможных убытков, связанных с таким отказом.<br/><br/>
          6.7. Существенным нарушением Договора со стороны Пользователя являются неисполнение или ненадлежащее
          исполнение обязанностей, предусмотренных п.п.3.3.2.-3.3.6 Договора<br/><br/>

          <strong>7. РЕЗУЛЬТАТЫ ИНТЕЛЛЕКТУАЛЬНОЙ ДЕЯТЕЛЬНОСТИ</strong><br/><br/>
          7.1. Исключительное право на Произведения и Курс как составное произведение принадлежит Компании.
          Исключительное право на фотографии и изображения, направляемые Пользователю для выполнения заданий,
          принадлежит Компании.
          Пользователю запрещается записывать, копировать, скачивать, сохранять распространять, передавать,
          транслировать, демонстрировать, продавать, выдавать лицензии, изменять, преобразовывать или каким-либо
          иным образом использовать Произведения, Курс, фотографии и изображения, кроме следующих способов
          (перечень исчерпывающий):<br/>
          - Пользователь вправе только смотреть и слушать Произведения и только в личных, некоммерческих целях;<br/>
          - Пользователь вправе копировать, сохранять и вносить изменения в фотографии и изображения в целях
          выполнения заданий Компании.<br/><br/>
          7.2. Программное обеспечение Сайта и Платформы, их содержимое без ограничений, включая все переработки,
          исправления, модификации, дополнения, обновления и/или усовершенствования и наполнение, и связанная с ним
          техническая/эксплуатационная документация предназначается не для продажи, и никаких прав в отношении Сайта
          и Платформы Пользователь не приобретает, за исключением права использования по прямому функциональному
          назначению в соответствии с условиями настоящего Договора (просмотра контента, оплаты Пакетов услуг).<br/><br/>
          7.3. Размещая на Сайте, Платформе тексты, фотографии, отзывы, выполненные работы и другой контент, участвуя
          в видеоконференциях, Пользователь предоставляет Компании неисключительные права на такой контент и
          использование собственного изображения в том числе: запись, публичный показ, переработку, воспроизведение,
          доведение до всеобщего сведения (в том числе путем публикации на сайте и социальных сетях Компании) без
          ограничения территории и срока, без выплаты вознаграждения.<br/><br/>

          8. СРОК ДЕЙСТВИЯ ДОГОВОРА. ОДНОСТОРОННИЙ ОТКАЗ ОТ ДОГОВОРА. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ.
          ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ<br/><br/>
          8.1. Договор вступает в силу с момента акцепта Пользователем настоящей Оферты и действует до полного
          исполнения Сторонами взятых на себя обязательств.<br/><br/>
          8.2. Пользователь вправе отказаться от настоящего Договора в одностороннем внесудебном порядке
          до первой авторизации на Платформе, а также в течение 3 (трех) календарных дней после первой авторизации.
          В этом случае Компания осуществит возврат полученных от Пользователя денежных средств в течение 30 (тридцати)
          календарных дней после получения уведомления об отказе от Договора за вычетом банковских комиссий.
          Односторонний отказ Пользователя от Договора в более поздние сроки признается невозможностью исполнения
          по причинам, связанным с Пользователем, и возврат денежных средств Компанией не осуществляется.<br/><br/>
          8.3. Направление досудебной претензии является обязательным. Срок рассмотрения досудебной претензии
          Стороной-получателем – 30 календарных дней.<br/><br/>
          8.4. Все споры, разногласия или требования, которые могут возникнуть из настоящего договора или в связи с ним,
          в т.ч. связанные с его изменением, расторжением, исполнением, недействительностью или толкованием,
          подлежат рассмотрению в суде по месту нахождения ответчика. Применимым правом является материальное
          право Республики Беларусь.<br/><br/>
          8.5. Правила и порядок обработки персональных данных Пользователей изложены в политике конфиденциальности,
          опубликованной на сайте  http://________/ .<br/><br/>

          Реквизиты Компании<br/>
          ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ООО Ретач клаб»<br/>
          УНП: 193589603<br/>
          Юридический адрес: 220020, Республика Беларусь г. Минск, пр. Победителей, д. 103, офис 1104<br/>
          р/с: BY63 ALFA 3012 2A79 4600 1027 0000<br/>
          Банк получателя: ЗАО «Альфа-Банк»<br/>
          Эл.почта: documents@retouchclub.com<br/>
          Тел.: +375 29 384 5498<br/>

        </div>
        :
        <div className={`page-text ${theme}`}>

          <div className="text-right">
            Утверждено:<br/>
            Приказом директора ООО «Ретач клаб»<br/>
            Д.Д. Пучок<br/>
            № 1 от 26.04.2022<br/>
          </div>
          <br/>
          Если по какой-либо причине Вы не согласны с условиями настоящей Публичной Оферты на оказание услуг,
          Политикой Конфиденциальности, не используйте Интернет-ресурс easyschool.retouchclub.net
          и не авторизуйтесь на Платформе GetCourse.
          <br/><br/>
          <h2 className='text-center'>
            ПУБЛИЧНАЯ ОФЕРТА<br/>
            на оказание услуг<br/>
          </h2><br/>
          Минск, 26 апреля 2022 г.<br/><br/>

          ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ООО Ретач клаб» (далее — «Компания»), УНП 193589603, Республика Беларусь,
          публикует настоящую Публичную оферту на оказание услуг по предоставлению доступа к Курсу и консультационных услуг
          дистанционно (далее совместно и по отдельности именуемые — «Услуги»).<br/><br/>
          Настоящая Публичная оферта адресована любому лицу, осуществляющему доступ к Интернет-ресурсу
          easyschool.retouchclub.net (далее – Сайт) и представляет собой официальное предложение Компании
          заключить договор на оказание Услуг (далее – «Договор» или «Соглашение») на условиях,
          изложенных в настоящей Публичной оферте (далее – Оферта).<br/><br/>

          Настоящая Оферта вступает в силу с момента ее опубликования Компанией на Сайте и действует до момента отзыва Оферты Компанией.
          Компания оставляет за собой право внести изменения в условия настоящей Оферты и (или) отозвать Оферту
          в любой момент по своему усмотрению. В случае внесения Компанией изменений в Оферту,
          такие изменения вступают в силу с момента размещения измененного текста оферты в сети Интернет
          по адресу______________________, если иной срок вступления изменений в силу не определен дополнительно
          при таком размещении. При этом к отношениям сторон применяется редакция Оферты,
          размещенная на сайте Компании в момент акцепта оферты Пользователем (п.1.3).<br/><br/>
          Настоящая оферта составлена на русском языке.<br/><br/>
          В случае если вам меньше 18 лет или вы не достигли возраста совершеннолетия в соответствии
          с законодательством вашего государства, просим вас передать данную оферту вашим родителям или законным представителям.
          <br/><br/>
          <strong>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</strong><br/><br/>
          В целях Оферты нижеприведенные термины используются в следующих значениях:<br/>
          <strong>Акцепт Оферты</strong> — полное и безоговорочное принятие Оферты путем осуществления действий,
          указанных в п. 1.3. Оферты.<br/>
          <strong>Пользователь</strong> — совершеннолетнее лицо, осуществившее Акцепт Оферты.<br/>
          <strong>Договор</strong> — договор между Пользователем и Компанией на оказание Услуг на условиях,
          изложенных в Оферте, который заключается посредством Акцепта Оферты.<br/>
          <strong>Курс</strong> – Курс «Основы ретуши цифровых фотографий», размещенный на Платформе,
          состоящий из восемнадцати Произведений, объединенных единой темой, обладающих общей структурой повествования,
          включающих задания на выполнение практических работ. Исключительное право на Курс как на составное
          произведение принадлежит Компании. Краткое описание Курса содержится на Сайте.<br/>
          <strong>Произведения</strong> – аудиовизуальные произведения, исключительные права на которые принадлежат
          Компании и размещенные ею на Платформе в составе Курса.<br/>
          <strong>Пакет услуг</strong> - набор Услуг, предоставляемый Компанией по выбору Пользователя,
          включающих доступ к Курсу для целей онлайн-просмотра Произведений, входящих в его состав,
          и различный уровень консультативной поддержки Пользователя. Сведения о доступных Пакетах услуг содержатся на
          Сайте и являются неотъемлемой частью настоящей Оферты.<br/>
          <strong>Платформа</strong> – Платформа GetCourse, интернет-ресурс, расположенный в сети Интернет по
          адресу https://getcourse.ru, посредством которого Пользователю предоставляется доступ к Курсу.<br/>
          <strong>Электронная почта Компании</strong> - documents@retouchclub.com, адрес электронной почты Компании,
          используемый для переписки по вопросам исполнения и прекращения настоящего Договора или в связи с ним.<br/><br/>

          <strong>1. АКЦЕПТ И ПРЕДМЕТ ДОГОВОРА</strong><br/><br/>
          1.1. В соответствии с настоящим Договором Компания оказывает Пользователю Услуги по предоставлению доступа
          к Курсу для целей онлайн-просмотра Произведений, входящих в его состав, и консультационные услуги дистанционно
          согласно выбранному Пользователем Пакету услуг, а Пользователь обязуется оплатить такие Услуги.<br/>
          Оказание Услуг Компанией направленно только на удовлетворение познавательных потребностей граждан,
          не направлено на приобретение профессии, специальности, переподготовку и повышение квалификации руководящих
          работников, специалистов, рабочих (служащих).<br/><br/>
          1.2. Договор заключается посредством Акцепта Пользователем Оферты Компании.<br/><br/>
          1.3. Акцептом является оплата Пользователем выбранного им Пакета услуг.<br/>
          Акцепт является полным и безоговорочным. Если Пользователь не согласен с какими либо положениями Оферты,
          он не должен оплачивать Пакет услуг.<br/><br/>

          <strong>2. ПОРЯДОК И СРОКИ ОКАЗАНИЯ УСЛУГ</strong><br/><br/>
          2.1. На Сайте размещены описания Пакетов услуг, предоставляемых Компанией Пользователям, а также сведения об
          их стоимости. Описания Пакетов услуг являются неотъемлемой частью настоящей Оферты.<br/>
          Пользователь выбирает Пакет услуг и производит его оплату через Сайт (нажав кнопку «Оплатить» и перейдя на
          страницу системы приема платежей для внесения оплаты) либо сообщает о своем выборе Компании и получает
          ссылку на оплату по электронной почте. В указанных случаях оплата производится через
          платежную систему ________________ либо _______ Пользователь может также самостоятельно оплатить выбранный
          Пакет услуг на расчетный счет Компании.<br/><br/>
          Днем оплаты считается день зачисления денежных средств на расчетный счет Компании.<br/>
          2.2. В течение 3 (трех) рабочих дней со дня полной оплаты стоимости Пакета услуг Пользователь получает
          на адрес электронной почты, указанный им при оплате через платежную систему, либо дополнительно сообщенный
          Компании – при оплате через расчетный счет, ссылку для доступа и логин и пароль для авторизации на Платформе,
          посредством которой будут оказываться Услуги.<br/><br/>
          2.3. Дата начала оказания Услуг – день, когда Пользователь впервые авторизовался на Платформе.<br/>
          Период оказания услуг  - 6 месяцев.<br/>
          Дата окончания оказания Услуг – дата истечения шести месяцев со дня первой авторизации Пользователя на
          Платформе. Услуги могут быть оказаны досрочно.<br/><br/>
          2.4. В ходе оказания Услуг Пользователь получает:<br/>
          - поэтапный доступ к Произведениям Курса, т.е. возможность их онлайн-просмотра на весь период оказания услуг;<br/>
          - возможность получать консультацию по содержанию Произведений в общем чате на Платформе,
          тематической группе в Telegram с другими Пользователями;<br/>
          - иные консультационные услуги в соответствии с выбранным Пользователем Пакетом услуг.
          Консультационные услуги оказываются Компанией с использованием адреса электронной почты, номера телефона
          в мессенджере Telegram, сообщенными Пользователем, которые также используются для переписки по вопросам
          исполнения и прекращения настоящего Договора или в связи с ним.<br/><br/>
          2.5. Доступ к каждому следующему Произведению Курса предоставляется после выполнения задания,
          содержащегося в предыдущем Произведении, и направления выполненного задания Компании.
          Непредоставление ссылки для просмотра следующего Произведения по причине отсутствия выполненного задания
          по предыдущему Произведению не является нарушением обязательств со стороны Компании и не влечет возврата
          денежных средств Пользователю.<br/><br/>
          2.6. Если это предусмотрено соответствующим Пакетом Услуг по окончании изучения Пользователем Произведений.
          Пользователь вправе пройти итоговое тестирование и при положительных результатах получить
          Сертификат об успешном окончании Курса.<br/>
          Форма Сертификата утверждается Компанией.<br/><br/>
          2.7. Услуги, предусмотренные настоящим договором, оказываются Компанией исключительно лично Пользователю.<br/><br/>
          2.8. Компания вправе привлекать для оказания Услуг третьих лиц.<br/><br/>

          <strong>3. ПРАВА И ОБЯЗАННОСТИ СТОРОН</strong><br/><br/>
          3.1. Компания обязуется:<br/><br/>
          3.1.1. В сроки, установленные настоящим Договором, предоставить Пользователю, полностью оплатившему Пакет
          услуг, ссылку для доступа и логин и пароль к Личному кабинету (профилю), при условии соблюдения
          Пользователем обязанности, установленной п.3.3.1. Договора.<br/>
          3.1.2. Предоставлять доступ (ссылку) к следующему Произведению после получения выполненного задания
          по предыдущему Произведению;<br/>
          3.1.3. В случае отсутствия доступа к Произведениям Курса в связи с техническими неполадками
          Платформы Компания  обязана обеспечить  устранение неполадок либо предоставить альтернативный доступ к Произведениям.<br/>
          3.1.4. Осуществлять консультационную поддержку относительно предоставляемых Услуг, порядка и правил оплаты и
          заключения договора по Электронной почте Компании.<br/><br/>
          <strong>3.2. Компания вправе:</strong><br/><br/>
          3.2.1. В случае прекращения Договора по любым основаниям, удалить все предоставленные Пользователем данные
          на Платформе, личный кабинет (профиль) Пользователя, без возможности их восстановления, а также удалять
          Пользователя из общих чатов, тематических групп в Telegram.<br/>
          3.2.2. Удалять без объяснения причин размещенную Пользователем информацию.<br/>
          3.2.3. Приостановить оказание услуг в случае нарушения Пользователем встречных обязательств по Договору,
          и/или существенном нарушении Пользователем своих обязательств по Договору и сообщить об этом в электронном
          письме на адрес электронной почты Пользователя, сообщенный им при осуществлении платежей через Сайт
          либо дополнительно сообщенный после оплаты Пакета услуг на расчетный счет Компании либо в ходе исполнения Договора.<br/>
          3.2.4. Компания имеет иные права, предусмотренные настоящим Договором и применимым правом.<br/><br/>
          <strong>3.3. Пользователь обязуется:</strong><br/><br/>
          3.3.1. Предоставить Компании все сведения и данные, запрашиваемые на Сайте, Платформе или непосредственно
          Компанией, необходимые для оказания услуг. А также при оплате Пакета услуг непосредственно на расчетный
          счет Компании сообщить свой адрес электронной почты для направления логина и пароля, самостоятельно
          (без запроса Компании) направив соответствующее сообщение на Электронную почту Компании.<br/>
          3.3.2. Не осуществлять запись Произведений, их трансляцию, не сохранять их на свои устройства, не
          предоставлять и не распространять (публиковать, размещать на Интернет-сайтах, передавать или перепродавать
          третьим лицам, иным образом делать доступными иным лицам) в коммерческих или некоммерческих целях
          предоставляемые Компанией Пользователю информацию и материалы в рамках настоящего договора, не создавать
          на основе Курса и иной информации, полученной от Компании, свои информационные продукты с целью извлечения
          прибыли, а также использовать полученную в ходе оказания Услуг информацию каким-либо иным образом,
          кроме как для личного пользования.<br/>
          3.3.3. Не изменять каким бы то ни было способом программную часть Сайта, Платформы, не совершать любые
          действия, направленные на изменение функционирования и работоспособности Сайта, Платформы.<br/>
          3.3.4. Не размещать на Сайте, Платформе персональные данные третьих лиц.<br/>
          3.3.5. Не размещать на Сайте, Платформе рекламу, а также любую информацию, размещение, распространение
          которой запрещено или ограничено, в соответствии с законодательством Республики Беларусь, страны места
          нахождения/регистрации Пользователя и/или может влечь административную или уголовную ответственность
          и/или вынесение требований уполномоченным органом об устранении нарушений, ограничение доступа к интернет-ресурсу.<br/>
          3.3.6. Не передавать реквизиты доступа (ссылку, логин, пароль) для получения Услуг третьим лицам,
          не использовать Услуги совместно с третьими лицами.<br/>
          3.3.7. Хранить данные для использования Платформы (логин и пароль) в недоступном для любых третьих лиц месте.
          Пользователь несет полную ответственность и риски всех возможных негативных последствий, связанных с
          неисполнением настоящего правила, а также отвечает за все действия, совершенные от его имени на Платформе.
          Пользователь обязуется незамедлительно уведомить Компанию на Электронный адрес Компании о неправомерных
          действиях от его имени.<br/>
          3.3.8. Обеспечить за свой счет для целей получения Услуг без возмещения Компанией соответствующих затрат
          соответствие используемых Пользователем устройств, сетей, услуг связи требованиям, установленным на Сайте,
          Платформе либо в ином порядке сообщенным Компанией.<br/>
          3.3.9. Выполнять  иные обязанности, установленные настоящим Договором и применимым правом.<br/><br/>
          3.4. Пользователь вправе:<br/><br/>
          3.4.1. Требовать от Компании выполнения обязательств по настоящему Договору в установленные Договором сроки.<br/><br/>

          <strong>4. СТОИМОСТЬ УСЛУГ, ПОРЯДОК РАСЧЕТОВ, ОФОРМЛЕНИЕ АКТОВ ОБ ОКАЗАННЫХ УСЛУГАХ</strong><br/><br/>
          4.1. Стоимость Пакетов услуг указана на Сайте в белорусских рублях, в российских рублях, в Долларах США.
          Стоимость указана без НДС в связи с применением Компанией упрощенной системы налогообложения.<br/>
          Валюта договора и расчетов белорусские рубли - для резидентов Республики Беларусь;<br/>
          Валюта договора и расчетов российские рубли – для резидентов Российской Федерации;<br/>
          Валюта договора и расчетов Доллары США – для резидентов иных стран, кроме Республики Беларусь и Российской Федерации.<br/><br/>
          4.2. Сумма Договора равна стоимости выбранного Пользователем Пакета услуг.<br/><br/>
          4.3. Условия оплаты – предоплата 100%. Услуги считаются оплаченными с момента поступления полной стоимости
          Пакета услуг на расчетный счет Компании. После 100% оплаты стоимость Пакета услуг изменению не подлежит.
          При частичной оплате Пакета Услуг - Услуги считаются не оплаченными Пользователем и не оказываются Компанией.
          В случае непоступления оставшейся части оплаты за Пакет услуг в течение 10 рабочих дней с момента
          перечисления первой части платежа по нему действие Договора прекращается и вся полученная сумма оплаты,
          за исключением расходов Компании на ее перечисление, возвращается Пользователю.<br/><br/>
          4.4. Возврат денежных средств в суммах и по причинам, установленным настоящим Договором и применимым правом,
          осуществляются Компанией на те платежные реквизиты, с которых был осуществлен последний платеж Пользователя
          в адрес Компании, а если данные реквизиты по каким-либо причинам на момент совершения платежа Компанией
          использованы быть не могут - на любые другие известные Компании платежные реквизиты Пользователя.
          Если Пользователь желает получить платеж на какие-либо иные платежные реквизиты, он обязан предварительно
          уведомить об этом Компанию не позднее 4 календарных дней до момента совершения платежа посредством
          направления сообщения на Электронную почту Компании.<br/><br/>
          4.5. Банковские расходы по переводу – несет Пользователь, за исключением расходов
          платежных систем (посредников в расчетах).<br/><br/>
          4.6. Компания не осуществляет обработку персональных данных плательщиков, предоставляемых
          для проведения расчетов. Компания не хранит данные банковских карт плательщиков на своих ресурсах,
          включая серверы, облачные технологии и др.<br/><br/>
          4.7. Документом, подтверждающим оказание Услуг, является Акт об оказанных услугах.<br/><br/>
          Акт об оказанных услугах составляется каждой из Сторон единолично, без его направления на согласование и
          подписание другой Стороне.<br/>
          Датой оказания Услуг, указываемой в Акте, является более ранняя из дат:<br/>
          - дата направления Компанией по электронной почте Сертификата об успешном окончании курса
          (не для всех Пакетов Услуг);<br/>
          - дата окончания оказания Услуг, определенная согласно п.2.3. Договора.<br/><br/>
          4.8. В случае если Пользователь не воспользовался Пакетом услуг в любой мере
          (не осуществлял просмотр, не участвовал в обсуждениях, не задавал вопросы, не выполнял практические
          задания и проч.) Услуги считаются оказанными Компанией в полном объеме и надлежащим образом.<br/><br/>

          <strong>5. КАЧЕСТВО УСЛУГ</strong><br/><br/>
          5.1. Стороны соглашаются, что Услуги по Договору предоставляются на условиях «как есть»,
          и Компания не несет ответственности за соответствие качества Услуг целям, требованиям и /или ожиданиям
          Пользователя, также Компания не несет ответственности за нерегулярность в предоставлении Услуг, временные
          перебои в работе Платформы или отсутствие доступа к Сайту независимо от причин такой нерегулярности,
          перебоев или отсутствия доступа.<br/><br/>
          5.2. Невзирая на положения пункта 5.1. настоящего Договора Компания предпримет максимально возможные
          усилия для устранения каких-либо сбоев и ошибок в работе Сайта, Платформы в случае их возникновения,
          в максимально короткие сроки. В случае необходимости проведения работ по техническому обслуживанию
          или усовершенствованию Платформы, Сайта Компания вправе приостановить оказание Услуг.<br/><br/>
          5.3. По всем возникающим вопросам работы Платформы или Сайта Пользователь должен обращаться в службу
          технической поддержки на Сайте или путем отправки запроса в Компанию на Электронный адрес Компании.<br/><br/>
          5.4. Компания не предоставляет гарантий или обещаний, связанных с качеством, безопасностью и надежностью
          Услуг. Компания отказывается от всех подразумеваемых гарантий и заявлений, включая, среди прочего,
          любые гарантии товарной пригодности, соответствия любым целям, гарантий прав собственности, точности
          данных и ненарушения прав.<br/><br/>
          5.5. Пользователь использует любую информацию, доступ к которой Пользователь получил в ходе оказания
          Услуг по Договору, на свой страх и риск и самостоятельно несет ответственность за возможные последствия
          использования указанной информации<br/><br/>

          <strong>6. ОТВЕТСТВЕННОСТЬ СТОРОН</strong><br/><br/>
          6.1. Действия, совершенные с использованием логина и пароля Пользователя в Личном кабинете, считаются
          действиями самого Пользователя, и Пользователь несет за них полную ответственность.<br/><br/>
          6.2. Компания не несет ответственности за неиспользование Пользователем предоставленного ему доступа
          к Курсу и иных прав, входящих в Пакет услуг, в установленный срок оказания Услуг.
          Продление срока не предоставляется.<br/><br/>
          6.3. В случае привлечения Компании к ответственности за информацию, размещенную Пользователем,
          Компания вправе взыскать понесенные убытки, расходы (в том числе судебные), штрафы, пени, неустойки,
          компенсации морального вреда и вреда деловой репутации с соответствующего Пользователя.<br/><br/>
          6.4. Компания не несет ответственности за действия (бездействия) Пользователей в отношении друг друга,
          а также не несет ответственности перед Пользователями за неправомерные действия /бездействие третьих лиц.<br/><br/>
          6.5. Компания несет ответственность перед Пользователем только в виде возмещения реального ущерба.
          Упущенная выгода возмещению не подлежит. Общий размер ответственности Компании перед Пользователем
          ограничен стоимостью Пакета услуг, оплаченной Пользователем.<br/><br/>
          6.6. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по
          настоящему Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы либо
          событий чрезвычайного характера, которые возникли после заключения Договора, и которые Стороны не
          могли ни предвидеть, ни предотвратить разумными мерами, к которым в том числе относятся и действия и
          акты органов власти, препятствующие или делающие невозможным исполнение обязательств, установленных Договором.
          Если такие обстоятельства будут длиться более 6 месяцев, каждая из Сторон вправе отказаться от исполнения
          настоящего Договора без возмещения возможных убытков, связанных с таким отказом.<br/><br/>
          6.7. Существенным нарушением Договора со стороны Пользователя являются неисполнение или ненадлежащее
          исполнение обязанностей, предусмотренных п.п.3.3.2.-3.3.6 Договора<br/><br/>

          <strong>7. РЕЗУЛЬТАТЫ ИНТЕЛЛЕКТУАЛЬНОЙ ДЕЯТЕЛЬНОСТИ</strong><br/><br/>
          7.1. Исключительное право на Произведения и Курс как составное произведение принадлежит Компании.
          Исключительное право на фотографии и изображения, направляемые Пользователю для выполнения заданий,
          принадлежит Компании.
          Пользователю запрещается записывать, копировать, скачивать, сохранять распространять, передавать,
          транслировать, демонстрировать, продавать, выдавать лицензии, изменять, преобразовывать или каким-либо
          иным образом использовать Произведения, Курс, фотографии и изображения, кроме следующих способов
          (перечень исчерпывающий):<br/>
          - Пользователь вправе только смотреть и слушать Произведения и только в личных, некоммерческих целях;<br/>
          - Пользователь вправе копировать, сохранять и вносить изменения в фотографии и изображения в целях
          выполнения заданий Компании.<br/><br/>
          7.2. Программное обеспечение Сайта и Платформы, их содержимое без ограничений, включая все переработки,
          исправления, модификации, дополнения, обновления и/или усовершенствования и наполнение, и связанная с ним
          техническая/эксплуатационная документация предназначается не для продажи, и никаких прав в отношении Сайта
          и Платформы Пользователь не приобретает, за исключением права использования по прямому функциональному
          назначению в соответствии с условиями настоящего Договора (просмотра контента, оплаты Пакетов услуг).<br/><br/>
          7.3. Размещая на Сайте, Платформе тексты, фотографии, отзывы, выполненные работы и другой контент, участвуя
          в видеоконференциях, Пользователь предоставляет Компании неисключительные права на такой контент и
          использование собственного изображения в том числе: запись, публичный показ, переработку, воспроизведение,
          доведение до всеобщего сведения (в том числе путем публикации на сайте и социальных сетях Компании) без
          ограничения территории и срока, без выплаты вознаграждения.<br/><br/>

          8. СРОК ДЕЙСТВИЯ ДОГОВОРА. ОДНОСТОРОННИЙ ОТКАЗ ОТ ДОГОВОРА. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ.
          ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ<br/><br/>
          8.1. Договор вступает в силу с момента акцепта Пользователем настоящей Оферты и действует до полного
          исполнения Сторонами взятых на себя обязательств.<br/><br/>
          8.2. Пользователь вправе отказаться от настоящего Договора в одностороннем внесудебном порядке
          до первой авторизации на Платформе, а также в течение 3 (трех) календарных дней после первой авторизации.
          В этом случае Компания осуществит возврат полученных от Пользователя денежных средств в течение 30 (тридцати)
          календарных дней после получения уведомления об отказе от Договора за вычетом банковских комиссий.
          Односторонний отказ Пользователя от Договора в более поздние сроки признается невозможностью исполнения
          по причинам, связанным с Пользователем, и возврат денежных средств Компанией не осуществляется.<br/><br/>
          8.3. Направление досудебной претензии является обязательным. Срок рассмотрения досудебной претензии
          Стороной-получателем – 30 календарных дней.<br/><br/>
          8.4. Все споры, разногласия или требования, которые могут возникнуть из настоящего договора или в связи с ним,
          в т.ч. связанные с его изменением, расторжением, исполнением, недействительностью или толкованием,
          подлежат рассмотрению в суде по месту нахождения ответчика. Применимым правом является материальное
          право Республики Беларусь.<br/><br/>
          8.5. Правила и порядок обработки персональных данных Пользователей изложены в политике конфиденциальности,
          опубликованной на сайте  http://________/ .<br/><br/>

          Реквизиты Компании<br/>
          ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ООО Ретач клаб»<br/>
          УНП: 193589603<br/>
          Юридический адрес: 220020, Республика Беларусь г. Минск, пр. Победителей, д. 103, офис 1104<br/>
          р/с: BY63 ALFA 3012 2A79 4600 1027 0000<br/>
          Банк получателя: ЗАО «Альфа-Банк»<br/>
          Эл.почта: documents@retouchclub.com<br/>
          Тел.: +375 29 384 5498
        </div>
      }
    </div>
  );
}

export default PublicContract;