import React, { Suspense, useEffect, useState } from 'react';
import './other-page.scss';
import { data } from '../../data/data';
import { IFooter_Extended, IPrefooter_Extended } from '../../interface';
import Prefooter from '../prefooter';
import Footer from '../footer';
import { useLocation, useParams } from 'react-router-dom';
import StudentsResults from '../students-results';
import StudentsPortfolio from '../students-portfolio';
import NotFound from '../not-found';
import PrivacyPolicy from '../privacy-policy';
import Oferta from '../public-contract';

const ChooseTariff = React.lazy(() => import('../choose-tariff'));
const PaymentMethods = React.lazy(() => import('../payment-methods'));

const Header = React.lazy(() => import('../header'));

const OtherPage = (props: any) => {

  const { tariff } = props;
  const { url } = props;

  const [isTariffTableActive, setIsTariffTableActive] = useState(false);
  const [isCallbackActive, setIsCallbackActive] = useState(false);

  const [isMenuFirstLoad, setIsMenuFirstLoad] = useState(true);
  const [isMenuOn, setIsMenuOn] = useState(false);
  const toggleMenu = (): void => {
    if (isMenuFirstLoad) {
      setIsMenuFirstLoad(false);
      setIsMenuOn(!isMenuOn);
    } else setIsMenuOn(!isMenuOn);
  };

  let overlayFadeInClass = () => isMenuOn || isTariffTableActive || isCallbackActive ? 'overlay-fadeIn' : '';

  const getTheme = () => {
    let theme: string | null = localStorage.getItem('easyTheme');
    return theme ? JSON.parse(theme) : 'black';
  };
  const getLang = () => {
    let lang: string | null = localStorage.getItem('easyLang');
    return lang ? JSON.parse(lang) : 'ru';
  };
  const [theme, setTheme] = useState(getTheme());
  const [lang, setLang] = useState(getLang());
  const changeTheme = (themeBefore: string): void => {
    const theme = themeBefore === 'black' ? 'white' : 'black';
    localStorage.setItem('easyTheme', JSON.stringify(theme));
    setTheme(theme);
  };
  const changeLang = (lang: string): void => {
    localStorage.setItem('easyLang', JSON.stringify(lang));
    setLang(lang);
  };
  const getData = (lang: string): any => {
    switch (lang) {
      case 'ru':
        return data.ru;
      case 'en':
        return data.en;
      default:
        return data.ru;
    }
  };

  const getDataSection = (section: string) => {
    const data = getData(lang);
    switch (section) {
      case 'basic':
        return { basic: data.basic };
      case 'paymentMethods':
        return { basic: data.basic, paymentMethods: data.paymentMethods };
      case 'privacyPolicy':
        return { basic: data.basic, privacyPolicy: data.privacyPolicy };
      case 'publicContract':
        return { basic: data.basic, publicContract: data.publicContract };
      case 'chooseTariff':
        return { basic: data.basic, chooseTariff: data.chooseTariff };
      case 'section1':
        return { basic: data.basic, section1: data.section1 };
      case 'studentsResults':
        return { basic: data.basic, studentsResults: data.studentsResults };
      case 'studentsPortfolio':
        return {
          basic: data.basic,
          studentsResults: data.section5.portfolio,
          studentsPortfolio: data.studentsPortfolio
        };
      case 'prefooter':
        return { basic: data.basic, prefooter: data.prefooter };
      case 'footer':
        return { basic: data.basic, footer: data.footer };
    }
  };
  const dataAll = getData(lang);


  const handleOverlayClick = (e?: any) => {
    if (e.target.textContent === getDataSection('basic')?.basic.menu.menuTop[3].title) {
      localStorage.setItem('section5', '2');
    }

    if (e.target.textContent === getDataSection('basic')?.basic.menu.menuTop[2].title) {
      localStorage.setItem('section5', '1');
    }

    if (isMenuOn) setIsMenuOn(!isMenuOn);
    if (isCallbackActive) setIsCallbackActive(!isCallbackActive);
    if (isTariffTableActive) setIsTariffTableActive(!isTariffTableActive);
  };

  const [page, setPage] = useState<() => JSX.Element>(() => <></>);
  const getPage = (page: string) => {
    switch (page) {

      case 'not-found':
        return () => <NotFound />;
      case 'choose-tariff':

        const fromWhere = props.fromWhere || '';
        const tariff = props.tariff || 'default';
        return () => <ChooseTariff
          fromWhere={fromWhere}
          tariff={tariff}
          data={getDataSection('chooseTariff')}
          theme={theme}
          isTariffTableActive={isTariffTableActive}
          setIsTariffTableActive={setIsTariffTableActive}
        />;
      case 'payment-methods':
        return () => <PaymentMethods
          lang={lang}
          data={getDataSection('paymentMethods')}
          theme={theme}
          setIsCallbackActive={setIsCallbackActive}
        />;
      case 'privacy-policy':
        return () => <PrivacyPolicy
          lang={lang}
          data={getDataSection('privacyPolicy')}
          theme={theme}
          setIsCallbackActive={setIsCallbackActive}
        />;
      case 'public-contract':
        return () => <Oferta
          lang={lang}
          data={getDataSection('publicContract')}
          theme={theme}
          setIsCallbackActive={setIsCallbackActive}
        />;
      case 'students-results':
        return () => <StudentsResults
          data={getDataSection('studentsResults')}
          theme={theme}
        />;
      case 'students-portfolio':
        if (props.fromWhere) {
          return () => <StudentsPortfolio
            fromWhere={props.fromWhere}
            data={getDataSection('studentsPortfolio')}
            token="IGQVJYRVpwQnVCYTVfQ2lGY3dvSmp5dmRuVTVhRHl3TnRubEJleF9UdzhsbzA1UFhaU0QyUmVPV2lRaTRJZAWRReG1aRWVHTkFrTWlBYjdKZA2o5VGpKbHRWQ3g3T21xX3ZAKY0tINXVn"
            theme={theme}
          />;
        }
        return () => <StudentsPortfolio
          data={getDataSection('studentsPortfolio')}
          theme={theme}
        />;
      default:
        return () => <ChooseTariff data={getDataSection('chooseTariff')} />;
    }
  };
  useEffect(() => {
    // const Page: () => JSX.Element = getPage(url);
    return setPage(getPage(url));
  }, [url, isTariffTableActive]);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const params = location.search;
    if (params !== '') {
      const paramsSplitted = params.slice(1).split('&');
      console.log(paramsSplitted);
      paramsSplitted.forEach((param) => {
        const paramSplitted = param.split('=');
        if (paramSplitted.length === 2) {
          switch (paramSplitted[0]) {
            case 'lang':
              if (paramSplitted[1] === 'ru' || paramSplitted[1] === 'en') {
                localStorage.setItem('easyLang', JSON.stringify(paramSplitted[1]));
              }
              break;
            case 'theme':
              if (paramSplitted[1] === 'black' || paramSplitted[1] === 'white') {
                localStorage.setItem('easyTheme', JSON.stringify(paramSplitted[1]));
              }
              break;
          }
        }
      });
    }
  }, [location]);

  useEffect(() => {
    const html = document.getElementsByTagName('html') as HTMLCollectionOf<HTMLElement>;
    const body = document.getElementsByTagName('body') as HTMLCollectionOf<HTMLElement>;
    if (theme === 'white') {
      if (html[0].classList.contains('black')) html[0].classList.remove('black');
      if (!html[0].classList.contains('white')) html[0].classList.add('white');
      if (body[0].classList.contains('black')) body[0].classList.remove('black');
      if (!body[0].classList.contains('white')) body[0].classList.add('white');
    } else {
      if (html[0].classList.contains('white')) html[0].classList.remove('white');
      if (!html[0].classList.contains('black')) html[0].classList.add('black');
      if (body[0].classList.contains('white')) body[0].classList.remove('white');
      if (!body[0].classList.contains('black')) body[0].classList.add('black');
    }
  }, [theme]);

  return (
    <>
      <div id="overlay"
           className={`overlay ${overlayFadeInClass()}`}
           onClick={handleOverlayClick}>
      </div>
      <main className={`easy-main ${theme}`}>
        <Suspense fallback={<div className="loadingio-spinner-dual-ring-wfiizqs7edj">
          <div className="ldio-6lbj7tk3vqw">
            <div></div>
            <div>
              <div></div>
            </div>
          </div>
        </div>}>
          <Header
            showMenu={toggleMenu}
            exitMenu={handleOverlayClick}
            menuOn={isMenuOn}
            menuFirstLoad={isMenuFirstLoad}
            callbackThanks={dataAll.basic.callbackThanks}
            workingTime={dataAll.basic.menu.workingTime}
            menuTop={dataAll.basic.menu.menuTop}
            menuBottom={dataAll.basic.menu.menuBottom}
            socialLinks={dataAll.basic.socialLinks}
            changeTheme={changeTheme}
            changeLang={changeLang}
            lang={lang}
            theme={theme}
            // callBackFormActiveFromOther={callBackForm}
            btnTextCallback={dataAll.section1.btnTextCallback}
            isCallbackActive={isCallbackActive}
            setIsCallbackActive={setIsCallbackActive}
          />
          {page}
          <Prefooter
            data={getDataSection('prefooter') as IPrefooter_Extended}
            theme={theme}
          />
          <Footer
            data={getDataSection('footer') as IFooter_Extended}
            theme={theme}
          />
        </Suspense>
      </main>
    </>
  );
};

export default OtherPage;