import {IDataLang} from "../interface";

export const data: IDataLang = {
  ru: {
    basic: {
      main: "Главная",
      browserSupport: "Ваш браузер не поддерживает HTML5 видео.",
      socialLinks: {
        instagram: "https://instagram.com/easyschool.agency?igshid=YmMyMTA2M2Y=",
        telegram: "https://t.me/retouchclub",
        youtube: "https://www.youtube.com/channel/UCdo0lw8i750yKE95g4pj3jA"
      },
      callbackThanks: {
        thanksTitle: "Заявка принята",
        thanksMessage: "Наш менеджер<br/> скоро свяжется<br/> с вами!"
      },
      menu: {
        menuTop: [
          {
            title: "Обучение",
            link: "#section2",
          },
          {
            title: "Стоимость",
            link: "#section3",
          },
          {
            title: "Программа",
            link: "#section5",
          },
          {
            title: "О школе",
            link: "#section5",
          },
          {
            title: "Интенсив",
            link: "#section6",
          },
          {
            title: "Портфолио учеников",
            link: "students-portfolio",
          },
        ],
        menuBottom: [
          {
            title: "Публичный договор",
            // link: "https://easyschool.retouchclub.net/cms/system/legal",
            link: "public-contract",
            class: "public-contract"
          },
          {
            title: "Политика конфиденциальности",
            // link: "https://easyschool.retouchclub.net/politika",
            link: "privacy-policy",
            class: "privacy-policy"
          },
          {
            title: "Способы оплаты",
            link: "payment-methods",
            class: "payment-methods"
          },
        ],
        workingTime: {
          title: "Режим работы",
          info: "с 10.00 до 19.00",
        }
      }

    },
    section1: {
      title: "Онлайн курс<br /> по коммерческой ретуши",
      description: "Аналитика и опыт ведущего ретушь агентства Европы и США Retouchclub.com",
      btnTextCallback: "Заказать звонок",
      btnText: "Купить курс",
      textSignUpCourse: "Записаться на курс",
      promo: "Промо курса",

    },
    section2: {
      title: "Обучение в ",
      blockTitle: "Твой быстрый старт в коммерческую ретушь<br/> для зарубежных заказчиков",
      blocks: [
        "Для новичков в ретуши и опытных специалистов, которые хотят расти в профессии.<br/>\n" +
        " А также фотографов, SMM-менеджеров и контент-мейкеров.",
        "12 насыщенных видеоуроков. Осваиваем инструменты и техники в Photoshop,\n" +
        " о которых не знают даже опытные ретушеры.",
        "Учимся ретушировать фотографии по международным стандартам качества для зарубежного рынка.",
        "Получаем обратную связь от опытного куратора и исправляем ошибки.\n" +
        "Наставник научит замечать и исправлять неточности, которые встречаются у 90% практикующих ретушеров.",
        "Развиваем насмотренность и улучшаем качество работ под присмотром хэд ретушеров.",
        "Готовим первые фотографии для портфолио и учимся формировать коммерческое предложение для зарубежных заказчиков.",
        "Практикуемся на 30+ фотографиях разной сложности по техническим заданиям из реальных проектов агентства ретуши.",
        "Получаем знания и навыки, благодаря которым сможем выставлять чек на 15-25$ за ретушь одной фотографии.",
        "Готовимся выйти на рынок ретуши и начать зарабатывать уже через 3-4 месяца обучения.",
        "Учимся находить заказчиков, которые хорошо оплачивают заказы.",
        "Трудоустраиваем лучших учеников в международное ретушь-агентство Retouch Club.\n" +
        "          Совмещаем дистанционную работу в команде с частным фрилансом."


        ],
      blocksNames: [
        "Теория",
        "Практика",
        "Работа",
        "Результат",
      ],
      btn: "Результаты обучения"
    },
    qualityRetouch: {
      title: "Качественная ретушь,<br/> которой вы научитесь на курсе",
      btn1: "Результаты учеников",
      btn2: "Работы учеников",
    },
    section3: {
      title: "Выберите свой тариф",
      tariffSlogan: "Лучший выбор",
      tariffMore: "Подробнее",
      tariffUrls: ["self", "selfPlus", "team", "vip"],
      tariffs:
        [
          {
            title: "Self-education",
            desc: "Для тех, кто готов сам изучать уроки и практиковаться",
            pointsBasic: [
              "12 подробных видеоуроков",
              "30+ фотографий для обработки домашних заданий",
              "Годовой доступ к материалам курса",
              "Ответы на все ваши вопросы в общем чате курса"
            ],
            pointsExtra: [
              "Бонусные уроки “Продающее портфолио ретушера” и “Стратегия поиска клиентов”",
            ]
          },
          {
            title: "Self-education +",
            desc: "Для тех, кто готов сам изучать уроки и практиковаться",
            pointsBasic: [
              "12 подробных видеоуроков",
              "30+ фотографий для обработки домашних заданий",
              "Годовой доступ к материалам курса",
              "Ответы на все ваши вопросы в общем чате курса"
            ],
            pointsExtra: [
              "Бонусные уроки “Продающее портфолио ретушера” и “Стратегия поиска клиентов”",
            ]
          },
          {
            title: "Team support",
            desc: "Для тех, кто готов быстро освоить профессию благодаря персональным разборам от  кураторов",
            pointsBasic: [
              "12 подробных видеоуроков",
              "30+ фотографий для обработки домашних заданий",
              "Годовой доступ к материалам курса",
              "Ответы на все ваши вопросы в общем чате курса"
            ],
            pointsExtra: [
              "Подробные видеоразборы работ кураторами",
              "6 фотографий для портфолио",
              "3 месяца кураторской поддержки",
              "Именной сертификат по окончанию курса",
            ]
          },
          {
            title: "Vip",
            desc: "Для тех, кто привык получать максимум от обучения",
            pointsBasic: [
              "12 подробных видеоуроков",
              "30+ фотографий для обработки домашних заданий",
              "Годовой доступ к материалам курса",
              "Приоритет в общении с кураторами (ответы на вопросы в течение дня)"
            ],
            pointsExtra: [
              "Бонусные уроки “Продающее портфолио ретушера” и “Стратегия поиска клиентов”",
              "Подробные видеоразборы работ курса",
              "12 фотографий для портфолио",
              "4 месяца кураторской поддержки",
              "Групповые онлайн-уроки по тренировке навыков арбитража",
              "Именной сертификат по окончанию курса",
            ]
          },
        ]
    },
    section4: {
      title: "Комфортное обучение с нуля",
      blocks: [
        {
          title: "По-настоящему “С нуля”",
          desc: "Вам не нужно предварительно учиться азам ретуши и знать все инструменты " +
            "в программе Photoshop. Даже если вы никогда не пробовали обрабатывать фотографии," +
            " мы всему обучим вас на курсе.",
          url: "../../assets/img/block4/1.png"
        },
        {
          title: "Быстрый и легкий старт",
          desc: "Для работы не нужно покупать дорогостоящее оборудование. Достаточно ноутбука " +
            "или компьютера. Также вам понадобится самый простой планшет для ретуши, который " +
            "стоит не более 40$.",
          url: "../../assets/img/block4/2.png"
        },
        {
          title: "Поддержка",
          desc: "Опытные кураторы всегда готовы ответить на ваши вопросы в чате. Привилегии в поддержке на тарифе VIP",
          url: "../../assets/img/block4/3.png"
        },
        {
          title: "В свободное время",
          desc: "Совмещайте курс с работой / учебой / уходом за детьми. Вы сами выбираете время для занятий.",
          url: "../../assets/img/block4/4.png"
        },
        {
          title: "Опытные преподаватели",
          desc: "Все кураторы являются действующими ретушерами международного агентства, которое" +
            " работает с заказчиками из Европы и США. Никакой пустой теории — вы получите" +
            " концентрат тех знаний и навыков, которые специалисты получили во время " +
            "многолетней практики.",
          url: "../../assets/img/block4/5.png"
        },
        {
          title: "Учеба без ограничений",
          desc: "Возвращайтесь к уже просмотренным видеоурокам в любое время. Все материалы " +
            "доступны целый год. А групповой чат курса останется с вами даже после окончания обучения.",
          url: "../../assets/img/block4/6.png"
        },
      ],
      btn: "Что нужно для старта",
    },
    section5: {
      title: "Курс «Коммерческий ретушер<br/> международного уровня»",
      programCourseBlock: {
        blocks: [
          "Программа курса",
          "Об агентстве",
          "Команда",
          "Наши клиенты",
          "Портфолио",
        ],
        lessonName: "Урок",
        programCourse: [
          {
            id: 1,
            title: "Самые важные теоретические знания в компактном формате",
            url: "1.png",
            titleSmall: "Интерфейс программы Adobe Photoshop",
            desc: "Легкое начало работы. Знакомимся с окнами " +
              "и панелями инструментов, учимся сохранять файлы в разных форматах, " +
              "разбираем самые частые вопросы, возникающие на старте. "
          },
          {
            id: 2,
            title: "Самые важные теоретические знания в компактном формате",
            url: "2.png",
            titleSmall: "Основные инструменты ретуши",
            desc: "Ваша первая проба пера. Углубленно изучаем основные инструменты ретуши и " +
              "учимся творчески использовать их на практике. "
          },
          {
            id: 3,
            title: "Самые важные теоретические знания в компактном формате",
            url: "3.png",
            titleSmall: "Маски и слои",
            desc: "Разбираем все необходимые для работы действия со слоями. " +
              "Знакомимся с принципом работы корректирующих слоев и масок. "
          },
          {
            id: 4,
            title: "Самые важные теоретические знания в компактном формате",
            url: "4.png",
            titleSmall: "Архитектура ретуши и порядок обработки фото",
            desc: "Уникальная методика Easy School. Именно эти знания помогут достичь высокого уровня в обработке фото " +
              "и сформировать отличный вкус в ретуши. "
          },
          {
            id: 5,
            title: "Самые важные теоретические знания в компактном формате",
            url: "5.png",
            titleSmall: "Проявка RAW-файлов",
            desc: "1 этап ретуши. Используя данные из RAW-файла, готовим фотографию к ретуши и исправляем дефекты съемки."
          },
          {
            id: 6,
            title: "Самые важные теоретические знания в компактном формате",
            url: "6.png",
            titleSmall: "Техника Частотного разложения",
            desc: "2 этап ретуши. Учимся работать с фотографией " +
              "на отдельных частотах, устраняя дефекты текстуры " +
              "и цветовые пятна."
          },
          {
            id: 7,
            title: "Самые важные теоретические знания в компактном формате",
            url: "7.png",
            titleSmall: "Коррекции на новых слоях",
            desc: "3 этап ретуши. Учимся заменять дефектные области, работаем с выделением и трансформацией."
          },
          {
            id: 8,
            title: "Самые важные теоретические знания в компактном формате",
            url: "8.png",
            titleSmall: "Техника Dodge & Burn",
            desc: "4 этап ретуши. Работаем со светотеневыми пятнами"
          },
          {
            id: 9,
            title: "Самые важные теоретические знания в компактном формате",
            url: "9.png",
            titleSmall: "Техническая цветокоррекция",
            desc: "5 этап ретуши. Работаем со смысловым центром фотографии, убираем следы после техник D&B и " +
              "частотного разложения, исправляем брак фокусировки. "
          },
          {
            id: 10,
            title: "Самые важные теоретические знания в компактном формате",
            url: "10.png",
            titleSmall: "Фильтр Пластика / Liquify",
            desc: "6 этап ретуши. Работаем с объемами, учимся находить дефекты пропорций фигуры, лица и исправлять их."
          },
          {
            id: 11,
            title: "Самые важные теоретические знания в компактном формате",
            url: "11.png",
            titleSmall: "Обтравка фотографий",
            desc: "7 этап ретуши. Учимся качественно отделять объект " +
              "от фона и экспортировать файлы с прозрачностью."
          },
          {
            id: 12,
            title: "Самые важные теоретические знания в компактном формате",
            url: "12.png",
            titleSmall: "Ускорение работы",
            desc: "Записываем экшены, создаем шаблоны кистей, устанавливаем горячие клавиши, кастомизируем рабочую среду."
          },
          {
            id: 13,
            title: "Самые важные теоретические знания в компактном формате",
            url: "13.png",
            titleSmall: "Бонусные уроки",
            desc: "Поэтапная ретушь со спикером курса. \n\r" +
              "Как искать клиентов?  \n\r" +
              "Как создать продающее коммерческое портфолио?"
          },
        ],
        watchProgram: "Обзор програмы"
      },
      agency: {
        title: "Школа ретуши Easy School — проект ведущего ретушь-агентства Европы и США Retouch Club",
        agencyBlocks: [
          {
            digits: "150 000 +",
            title: "успешных фотопроектов"
          },
          {
            digits: "900 +",
            title: "фотографов сотрудничают с нами"
          },
          {
            digits: "32",
            title: "из стольких стран к нам обращаются заказчики"
          },
          {
            digits: "300 +",
            title: "учеников стали проф. ретушерами "
          },
          {
            digits: "1",
            title: "уникальный фирменный стиль"
          },
          {
            digits: "96%",
            title: "успешно выполняемых заказов"
          },
        ],
        video: "videoAgency.mp4"
      },
      team: {
        title: "Практикуйтесь под кураторством опытных хэд ретушеров агентства Retouch Club",
        members: [
          {
            img: "sergey.png",
            name: "Сергей Петросов",
            position: "Соучредитель ведущего агентства Retouch Club и сооснователь школы ретуши Easy School.",
            descs: [
              "Сергей начинал работу как международный фотограф и уже более 10 лет занимается профессиональной ретушью. " +
              "Известные бренды, модели, рекламные кампании — он видел все процессы изнутри и непосредственно участвовал в них. " +
              "Благодаря этому опыту Сергей хорошо представляет, какую ретушь хотят видеть топовые заказчики со всего мира, " +
              "а Retouch Club работает по высоким стандартам качества.",
              "Созданная им школа Easy School выпустила уже более 500 профессиональных ретушеров. " +
              "Лучшие из них остаются работать в Retouch Club. И у вас тоже есть шанс присоединиться к команде."]
          },
          {
            img: "ekaterina.png",
            name: "Екатерина",
            position: "Ведущий ретушер-арбитражник агентства Retouch Club",
            descs: [
              "Екатерина — профессиональный ретушер агентства. К ней постоянно обращаются за советом менее опытные коллеги." +
              " Ежедневно она просматривает более 100 фотографий и исправляет ошибки начинающих ретушеров. ",
              "Екатерина умеет объяснять сложные вещи простыми словами и знает обо всех проблемах, с которыми сталкиваются новички" +
              " в ретуши. С ней вам будет не страшно делать первые шаги в профессии."],
          },
          {
            img: "ksenia.png",
            name: "Ксения",
            position: "Хэд ретушер-арбитражник и колорист агентства Retouch Club",
            descs: [
              "Ксения работает с заказчиками разной величины и знает, какую ретушь хотят видеть крупные клиенты.",
              "Она в совершенстве владеет международными стандартами ретуши и цветокоррекции " +
              "и готова поделиться с вами своими знаниями. ",
              "На курсе вы получите от Ксении развернутую обратную связь и мощный заряд мотивации."
            ],
          }
        ],
      },
      brandsTitle: "Наши клиенты",
      brandsImg: "brands.gif",
      portfolio: {
        title: "Наше портфолио",
        projects: [
          {
            img: "1.png"
          },
          {
            img: "2.png"
          },
          {
            img: "3.png"
          },
          {
            img: "4.png"
          },
          {
            img: "5.png"
          },
          {
            img: "6.png"
          },
          {
            img: "7.png"
          },
          {
            img: "8.png"
          },
          {
            img: "9.png"
          },
          {
            img: "10.png"
          },
          {
            img: "11.png"
          },
          {
            img: "12.png"
          },
        ]
      },
      watchRates: "Посмотреть пакеты",
    },
    section6: {
      slogan: "Попробуйте себя в новой профессии всего за 8$",
      title: "Интенсив по ретуши от Retouch Club",
      posts:
        [
          {
            id: 1,
            title: "На интенсиве вы поймете, подходит ли для вас профессия \n\rкоммерческий ретушер\n\r, " +
              "и выполните заказ по международным стандартным ретуши."
          },
          {
            id: 2,
            title: "Внесение оплаты для получения доступа к боту производится единоразово, " +
              "после чего пользователь получает доступ к учебным материалам и онлайн-чату с кураторами. ",
          },
          {
            id: 3,
            title: "Интенсив проходит в чат-боте Telegram. Есть два формата участия: "
          },
          {
            id: 4,
            title: "• \n\rНовичок\n\r — для тех, кто хочет проверить свои силы в новой профессии."
          },
          {
            id: 5,
            title: "• \n\rОпытный\n\r — для практикующих ретушеров, желающих выйти на международный рынок"
          },
          {
            id: 6,
            title: "Вас ждут видео-уроки по базовым техникам ретуши и поддержка кураторов. Если вы выполните работу " +
              "в течение \n\r7 дней\n\r, то получите персональный разбор всех ошибок от опытного ретушера. " +
              "Дедлайн станет для вас дополнительной мотивацией. "
          },
          {
            id: 7,
            title: "Чтобы присоединиться к интенсиву, нажмите на кнопку \n\rИду на интенсив\n\r. После оплаты вы получите " +
              "бессрочный доступ к урокам. Чат поддержки и возможность отправить работу на проверку " +
              "будут открыты в течение \n\r7 дней\n\r после оплаты."
          },
          {
            id: 8,
            title: "Присоединиться к интенсиву можно в любой момент."
          }
        ],
      priceTitle: "Стоимость",
      price: 8,
      btn: "Иду на интенсив"
    },
    section7: {
      title: "Телеграм-канал",
      postMore: "Подробнее",
      posts:
        [
          {
            id: 1,
            title: "Что такое ретушь, почему она коммерческая и для чего ее делать? ",
            desc: "Введение в профессию, после которого вы научитесь отличать устаревший подход к ретуши от современной обработки фото.",
            url: "post1.jpg",
            link: "https://t.me/retouchclub"
          },
          {
            id: 2,
            title: "Разбор трендовых съемок",
            desc: "Тренировка насмотренности, которая позволит вам лучше понимать, " +
              "как выглядит натуральная ретушь.",
            url: "post2.jpg",
            link: "https://t.me/retouchclub"
          },
          {
            id: 3,
            title: "Как быстро исправить брак фокусировки на фотографии?",
            desc: "Простая техника, с помощью которой вы научитесь подчеркивать объект съемки.",
            url: "post3.jpg",
            link: "https://t.me/retouchclub"
          },
        ]
    },
    prefooter: {
      contacts: "Наши контакты",
      phone: "+375 29 603 63 92",
      email: "info@retouchclub.com"
    },
    footer: {
      rights: "© Все права принадлежат ООО «Ретач клаб», УНП 193589603 <br />" +
        "Юридический адрес: г Минск проспект Победителей, 103, офис 1104",
      contract: "Публичный договор",
      policy: "Политика конфиденциальности",
      payment: "Способы оплаты"
    },
    paymentMethods: {
      title: "Способы оплаты",
      breadcrumb: "Способы оплаты",
      link: "payment-methods",
    },
    privacyPolicy: {
      title: "Политика конфиденциальности",
      breadcrumb: "Политика конфиденциальности",
      link: "privacy-policy",
    },
    publicContract: {
      title: "Публичный договор",
      breadcrumb: "Публичный договор",
      link: "public-contract",
    },
    chooseTariff: {
      title: "Оставить заявку",
      description: "Заполните форму и мы свяжемся с вами для уточнения деталей",
      videoText: "Посмотреть обзор пакета",
      breadcrumb: "Выбор тарифа",
      link: "choose-tariff",
      tariffUrls: [
        "self_education",
        "self_education_plus",
        "team_support",
        "team_support_2",
        "team_support_3",
        "vip",
        "vip_2",
        "vip_3",
        "call_1"
      ],
      form: {
        title: "Тарифные планы",
        tariffPlan: "Ваш тарифный план",
        payment: {
          title: "Оплата",
          options: ["Оставить заявку", "Купить сразу", "Купить в рассрочку"],
          installmentPlan: [
            {
              title: "Оплата в 2 этапа",
              percent: 20
            },
            {
              title: "Оплата в 3 этапа",
              percent: 30
            },
          ]
        },
        payPartsInfo: 'Инструкцию с подробной информацией об оформлении оплаты частями вы получите в Viber или по SMS.',
        plans: [
          {
            name: 'Выберите тарифный план',
            value: 'default',
            price: 0
          },
          {
            name: 'Self Education',
            value: 'self',
            // price: 239,
            price: 485,
          },
          {
            name: 'Self Education +',
            value: 'selfPlus',
            // price: 599,
            price: 759
          },
          {
            name: 'Team support',
            value: 'team',
            // price: 1199
            price: 1590
          },
          {
            name: 'VIP',
            value: 'vip',
            // price: 2399,
            price: 2490,
          },
        ],
        calc: {
          title: "Ваша заявка",
          tariff: "Тариф",
          total: "Сумма к оплате",
          btnSubmit: ["Забронировать место", "Перейти к оплате"]
        }
      },
      btn1: "Подробнее о курсе",
      btn2: "Программа курса",
      table: {
        columnNames: [
          "Теория и практика",
          "Теоретические уроки",
          "Практические уроки",
          "Групповые вебинары по отработке навыков арбитража",
          "Количество фотографий для отработки заданий",
          "Количество фотографий для проверки кураторами",
          "Доступ к материалам курса",
          "Именной сертификат по окончанию курса",
          "Поддержка кураторов",
          "Доступ к общему чату курса",
          "Проверка домашних заданий",
          "Подробный видеоразбор всех работ",
          "Персональные ответы на вопросы на платформе",
          "Кураторская поддержка",
          "Личный чат со спикером курса",
          "Портфолио",
          "Предоставление фото для портфолио",
          "Помощь в составлении портфолио",
          "Персональная консультация спикера по дальнейшему развитию",
          "Бонусные уроки",
          "Поэтапная ретушь со спикером",
          "Продающее портфолио ретушера",
          "Стратегия поиска клиентов"
        ],
        messages: [
          "1 год<br/>с даты покупки",
          "Услугу можно приобрести во время курса",
          "При успешной сдаче экзамена",
          "Проверка",
          "фото",
          "Только во время промежуточного и итогового экзаменов",
          "месяца",
          "с проверкой куратора",
          "Услугу можно приобрести во время курса",

          "Доступ для лучших учеников (Лучших учеников выбирают кураторы по итогам выполнения домашних заданий)",
"При оплате одним платежом",
          "из них обязательно",
          "с проверкой спикером курса",
        ]
      }
    },
    studentsResults: {
      title: "Ваши результаты",
      breadcrumb: "Обучение в Easy School",
      slogan: "Ваши результаты после<br/> <span className=\"bright\">2 недель</span> обучения у нас",
      btn1: "Главная",
      btn2: "Руботы учеников",
    },
    studentsPortfolio: {
      title: "Работы наших учеников",
      breadcrumb: "Обучение в Easy School",
      btn1: "Главная",
      btn2: "Результаты учеников",
    }
  },
  en: {
    basic: {
      main: "Main",
      browserSupport: "Your browser does not support HTML5 video.",
      socialLinks: {
        // instagram: "https://www.instagram.com/retouch_club/",
        instagram: "https://instagram.com/easyschool.agency?igshid=YmMyMTA2M2Y=",
        telegram: "https://t.me/retouchclub",
        youtube: "https://www.youtube.com/channel/UCdo0lw8i750yKE95g4pj3jA"
      },
      callbackThanks: {
        thanksTitle: "Order received",
        thanksMessage: "Our manager<br/> will call you <br/> back soon!"
      },
      menu: {
        menuTop: [
          {
            title: "Education",
            link: "#section2"
          },
          {
            title: "Price",
            link: "#section3"
          },
          {
            title: "Schedule",
            link: "#section5"
          },
          {
            title: "About school",
            link: "#section5"
          },
          {
            title: "Intensive",
            link: "#section6",
          },
          {
            title: "Students portfolio",
            link: "students-portfolio",
          },
        ],
        menuBottom: [
          {
            title: "Public contract",
            // link: "https://easyschool.retouchclub.net/cms/system/legal",
            link: "public-contract",
            class: "public-contract"
          },
          {
            title: "Privacy policy",
            // link: "https://easyschool.retouchclub.net/politika",
            link: "privacy-policy",
            class: "privacy-policy"
          },
          {
            title: "Payment methods",
            link: "payment-methods",
            class: "payment-methods"
          },
        ],
        workingTime: {
          title: "Working mode",
          info: "from 10.00 to 19.00",
        }
      }
    },
    section1: {
      title: "Online course<br /> of commercial retouching",
      description: "Analytics and experience of the leading retouching agency in Europe and the USA",
      btnTextCallback: "Order callback",
      btnText: "Buy course",
      textSignUpCourse: "Sign up on course",
      promo: "Course promo",
    },
    section2: {
      title: "Study at ",
      blockTitle: "Your quick start to commercial retouching<br/> for foreign clients",
      blocks: [
        "For beginners in retouching and experienced professionals who want to grow in the profession.<br/>\n" +
        " As well as photographers, SMM managers and content makers.",
        "12 saturated video lessons. We master tools and techniques in Photoshop, that even experienced retouchers do not know about.",
        "We learn how to retouch photos according to international quality standards for the foreign market.",
        "We receive feedback from an experienced mentor and correct errors.\n" +
        "The mentor will teach you to notice and correct inaccuracies that are found in 90% of practicing retouchers.",
        "We develop vision and improve the quality of work under the supervision of head retouchers.",
        "We are preparing the first photos for the portfolio and learning how to form a commercial offer for foreign customers.",
        "We practice on 30+ photographs of varying complexity according to the terms of reference from real projects of the retouching agency.",
        "We gain knowledge and skills, thanks to which we will be able to issue a check for $15-25 for retouching one photo.",
        "We are getting ready to enter the retouching market and start earning after 3-4 months of training.",
        "We learn to find customers who pay well for orders.",
        "We employ the best students in the international retouching agency Retouch Club.\n" +
        "          We combine remote work in a team with private freelancing."
      ],
      blocksNames: [
        "Theory",
        "Practice",
        "Work",
        "Result",
      ],
      btn: "Learning outcomes"
    },
    qualityRetouch: {
      title: "High-quality retouching,<br/> which you'll learn in the course",
      btn1: "Students results",
      btn2: "Students portfolio",
    },
    section3: {
      title: "Choose your plan",
      tariffSlogan: "Best choice",
      tariffMore: "More",
      tariffUrls: ["self", "selfPlus", "team", "vip"],
      tariffs:
        [
          {
            title: "Self-education",
            desc: "For those who are ready to learn lessons and practice on their own",
            pointsBasic: [
              "12 detailed video tutorials",
              "30+ photos for processing homework",
              "Annual access to course materials",
              "Answers to all your questions in the general course chat"
            ],
            pointsExtra: [
              "Bonus Lessons “Selling retoucher portfolio” and “Client search strategy”",
            ]
          },
          {
            title: "Self-education +",
            desc: "For those, who are ready to learn lessons and practice on their own",
            pointsBasic: [
              "12 detailed video tutorials",
              "30+ photos for processing homework",
              "Annual access to course materials",
              "Answers to all your questions in the general course chat"
            ],
            pointsExtra: [
              "Bonus Lessons “Selling retoucher portfolio” and “Client search strategy”",
            ]
          },
          {
            title: "TeamSmall support",
            desc: "For those, who are ready to master the profession quickly with personal analysis from mentors",
            pointsBasic: [
              "12 detailed video tutorials",
              "30+ photos for processing homework",
              "Annual access to course materials",
              "Answers to all your questions in the general course chat"
            ],
            pointsExtra: [
              "Detailed video analysis of works by mentors",
              "6 photos for portfolio",
              "3 months of mentoring support",
              "Personal certificate at the end of the course",
            ]
          },
          {
            title: "Vip",
            desc: "For those, who are used to get the most out of learning",
            pointsBasic: [
              "12 detailed video tutorials",
              "30+ photos for processing homework",
              "Annual access to course materials",
              "Priority in communication with mentors (answers to questions during the day)"
            ],
            pointsExtra: [
              "Bonus Lessons “Selling retoucher portfolio” and “Client search strategy”",
              "Detailed video analysis of works by mentors",
              "12 photos for portfolio",
              "4 months of mentoring support",
              "Group online lessons of training arbitrage skills",
              "Personal certificate at the end of the course",
            ]
          },
        ]
    },
    section4: {
      title: "Comfortable learning from scratch",
      blocks: [
        {
          title: "Truly “from scratch”",
          desc: "You do not need to learn the basics of retouching and know all the tools " +
            "in Photoshop. Even if you have never tried photo editing," +
            " we will teach you everything in the course.",
          url: "../../assets/img/block4/1.png"
        },
        {
          title: "Quick and easy start",
          desc: "You do not need to buy expensive equipment to work. Laptop or" +
            "personal computer is enough. You will also need the simplest tablet for retouching, which " +
            "costs no more than $40.",
          url: "../../assets/img/block4/2.png"
        },
        {
          title: "Support 24/7",
          desc: "Experienced mentors are always ready to answer your questions in the chat.",
          url: "../../assets/img/block4/3.png"
        },
        {
          title: "In free time",
          desc: "Combine the course with work/study/childcare. You choose your own time to practice.",
          url: "../../assets/img/block4/4.png"
        },
        {
          title: "Experienced teachers",
          desc: "All mentors are active retouchers of an international agency, that" +
            " work with customers from Europe and the USA. No empty theory - you get" +
            " concentrate of the knowledge and skills that specialists received during " +
            "many years of practice.",
          url: "../../assets/img/block4/5.png"
        },
        {
          title: "Study without limits",
          desc: "Go back to the videos you've already watched at any time. All materials " +
            "available all year long. And the group chat of the course will stay with you even after graduation.",
          url: "../../assets/img/block4/6.png"
        },
      ],
      btn: "What's for the start",
    },
    section5: {
      title: "Course «Commercial retoucher<br/> of the international level»",
      programCourseBlock: {
        blocks: [
          "Course program",
          "Agency",
          "Team",
          "Our clients",
          "Portfolio",
        ],
        lessonName: "Lesson",
        programCourse: [
          {
            id: 1,
            title: "The most important theoretical knowledge in a compact format",
            url: "1.png",
            titleSmall: "Adobe Photoshop interface",
            desc: "Easy start. Getting to know windows" +
              "and toolbars, learn how to save files in different formats, " +
              "we analyze the most common questions that arise at the start. "
          },
          {
            id: 2,
            title: "The most important theoretical knowledge in a compact format",
            url: "2.png",
            titleSmall: "Basic retouching tools",
            desc: "Your first attempt at writing. We study in depth the basic retouching tools and " +
              "learn to use them creatively in practice. "
          },
          {
            id: 3,
            title: "The most important theoretical knowledge in a compact format",
            url: "3.png",
            titleSmall: "Masks and layers",
            desc: "We analyze all the actions necessary for working with layers. " +
              "Learn how adjustment layers and masks work. "
          },
          {
            id: 4,
            title: "The most important theoretical knowledge in a compact format",
            url: "4.png",
            titleSmall: "Retouching architecture and photo processing procedure",
            desc: "The unique methodology of Easy School. It is this knowledge that will help to achieve a high level in photo processing. " +
              "and form a great taste in retouching. "
          },
          {
            id: 5,
            title: "The most important theoretical knowledge in a compact format",
            url: "5.png",
            titleSmall: "RAW file development",
            desc: "1 stage retouching. Using the data from the RAW file, we prepare the photo for retouching and fix shooting defects."
          },
          {
            id: 6,
            title: "The most important theoretical knowledge in a compact format",
            url: "6.png",
            titleSmall: "Frequency Decomposition Technique",
            desc: "2 stage retouching. Learning to work with photography " +
              "at individual frequencies, eliminating texture defects " +
              "and color spots."
          },
          {
            id: 7,
            title: "The most important theoretical knowledge in a compact format",
            url: "7.png",
            titleSmall: "Adjustments on new layers",
            desc: "3rd stage of retouching. We learn to replace defective areas, work with selection and transformation."
          },
          {
            id: 8,
            title: "The most important theoretical knowledge in a compact format",
            url: "8.png",
            titleSmall: "Technique Dodge & Burn",
            desc: "4th stage of retouching. Working with light spots"
          },
          {
            id: 9,
            title: "The most important theoretical knowledge in a compact format",
            url: "9.png",
            titleSmall: "Technical color correction",
            desc: "5th stage of retouching. We work with the semantic center of the photo, remove traces after the D&B and " +
              "frequency decomposition, fixing the marriage of focus. "
          },
          {
            id: 10,
            title: "The most important theoretical knowledge in a compact format",
            url: "10.png",
            titleSmall: "Filter Plastic / Liquify",
            desc: "6th stage of retouching. We work with volumes, learn to find defects in the proportions of the figure, face and correct them."
          },
          {
            id: 11,
            title: "The most important theoretical knowledge in a compact format",
            url: "11.png",
            titleSmall: "Clipping photos",
            desc: "7th stage of retouching. Learning to qualitatively separate an object " +
              "from background and export files with transparency."
          },
          {
            id: 12,
            title: "The most important theoretical knowledge in a compact format",
            url: "12.png",
            titleSmall: "Speed up work",
            desc: "Recording actions, creating brush templates, setting hotkeys, customizing the working environment."
          },
          {
            id: 13,
            title: "The most important theoretical knowledge in a compact format",
            url: "13.png",
            titleSmall: "Bonus lessons",
            desc: "Step by step retouching with the speaker of the course. \n\r" +
              "How to find clients?  \n\r" +
              "How to create a selling commercial portfolio?"
          },
        ],
        watchProgram: "Program Overview"
      },
      agency: {
        title: "Retouching school Easy School — project of the leading retouching agency in Europe and the USA Retouch Club",
        agencyBlocks: [
          {
            digits: "150 000 +",
            title: "successful photo projects"
          },
          {
            digits: "900 +",
            title: "photographers cooperate with us"
          },
          {
            digits: "32",
            title: "customers from many countries contact us"
          },
          {
            digits: "300 +",
            title: "students became prof. retouchers"
          },
          {
            digits: "1",
            title: "unique corporate style"
          },
          {
            digits: "96%",
            title: "successfully completed orders"
          },
        ],
        video: "videoAgency.mp4"
      },
      team: {
        title: "Practice under the supervision of experienced head retouchers of the agency Retouch Club",
        members: [
          {
            img: "sergey.png",
            name: "Sergey Petrosov",
            position: "Co-founder of lead agency Retouch Club and co-founder of the school of retouching Easy School.",
            descs: [
              "Sergey started as an international photographer and has been professional retouching for over 10 years. " +
              "Well-known brands, models, advertising campaigns - he saw all the processes from the inside and directly participated in them. " +
              "Thanks to this experience, Sergey has a good idea of what kind of retouching top customers from all over the world want to see, " +
              "and Retouch Club works to high quality standards.",
              "The Easy School he created has already produced more than 500 professional retouchers. " +
              "The best of them stay to work in Retouch Club. And you also have a chance to join the team."]
          },
          {
            img: "photo2.png",
            name: "Ekaterina",
            position: "Lead retoucher-arbitrator of agency Retouch Club",
            descs: [
              "Ekaterina is a professional retoucher of the agency. Less experienced colleagues constantly turn to her for advice." +
              " She reviews more than 100 photos every day and corrects the mistakes of novice retouchers. ",
              "Ekaterina knows how to explain complex things in simple terms and knows about all the problems that beginners face" +
              " in retouch. With her, you will not be afraid to take the first steps in the profession."],
          },
          {
            img: "photo3.png",
            name: "Kseniya",
            position: "Head retoucher-arbitrator and agency colorist Retouch Club",
            descs: [
              "Ksenia works with clients of various sizes and knows what kind of retouching large clients want to see.",
              "She is fluent in international standards of retouching and color correction. " +
              "and ready to share her knowledge with you. ",
              "On the course, you will receive detailed feedback from Ksenia and a powerful boost of motivation."
            ],
          }
        ],
      },
      brandsTitle: "Our partners",
      brandsImg: "brands.gif",
      portfolio: {
        title: "Our portfolio",
        projects: [
          {
            img: "1.png"
          },
          {
            img: "2.png"
          },
          {
            img: "3.png"
          },
          {
            img: "4.png"
          },
          {
            img: "5.png"
          },
          {
            img: "6.png"
          },
          {
            img: "7.png"
          },
          {
            img: "8.png"
          },
          {
            img: "9.png"
          },
          {
            img: "10.png"
          },
          {
            img: "11.png"
          },
          {
            img: "12.png"
          },
        ]
      },
      watchRates: "Watch rates",
    },
    section6: {
      slogan: "Try yourself in a new profession for only $8",
      title: "Retouching intensive from Retouch Club",
      posts:
        [
          {
            id: 1,
            title: "At the intensive, you will understand if the profession of a commercial retoucher is right for you, " +
              "and complete the order according to international standard retouching."
          },
          {
            id: 2,
            title: "It is one-time payment for access to the bot, " +
              "after which the user gets access to educational materials and online chat with mentors. ",
          },
          {
            id: 3,
            title: "The intensive takes place in the Telegram chat bot. There are two forms of participation: "
          },
          {
            id: 4,
            title: "• \n\rNovice\n\r — for those, who want to test their strength in a new profession.."
          },
          {
            id: 5,
            title: "• \n\rExperienced\n\r — for practicing retouchers who want to enter the international market"
          },
          {
            id: 6,
            title: "You will find video tutorials of basic retouching techniques and support from curators. If you do the work " +
              "within \n\r7 days\n\r, you will receive a personal analysis of all errors from an experienced retoucher. " +
              "The deadline will be an additional motivation for you. "
          },
          {
            id: 7,
            title: "To join the intensive, click on the \n\rgo to intensive\n\r button. After payment you will receive " +
              "unlimited access to lessons. Support chat and the ability to submit work for review " +
              "will be opened within \n\r7 days\n\r after payment."
          },
          {
            id: 8,
            title: "You can join the intensive at any time."
          }
        ],
      priceTitle: "Сost",
      price: 8,
      btn: "Go to intensive"
    },
    section7: {
      title: "Telegram-channel",
      postMore: "More",
      posts:
        [
          {
            id: 1,
            title: "What is retouching, why is it commercial and why do we need to do it?",
            desc: "An introduction to the profession, after which you will learn to distinguish the outdated approach to retouching from modern photo processing.",
            url: "post1.jpg",
            link: "https://t.me/retouchclub"
          },
          {
            id: 2,
            title: "Analysis of trend shootings",
            desc: "Mindfulness training that will allow you to better understand, " +
              "how natural retouching looks like.",
            url: "post2.jpg",
            link: "https://t.me/retouchclub"
          },
          {
            id: 3,
            title: "How to quickly fix a focus defect in a photo?",
            desc: "A simple technique with which you will learn how to emphasize the subject of shooting.",
            url: "post3.jpg",
            link: "https://t.me/retouchclub"
          },
        ]
    },

    prefooter: {
      contacts: "Our contacts",
      phone: "+375 29 603 63 92",
      email: "info@retouchclub.com"
    },
    footer: {
      rights: "© All rights reserved by Retouch Club LLC, UNP 193589603 <br />" +
        "Registered office: Minsk, Pobediteley avenue, 103, office 1104",
      contract: "Public contract",
      policy: "Privacy Policy",
      payment: "Payment methods"
    },
    paymentMethods: {
      title: "Payment methods",
      breadcrumb: "Payment methods",
      link: "payment-methods"
    },
    privacyPolicy: {
      title: "Privacy policy",
      breadcrumb: "Privacy policy",
      link: "privacy-policy",
    },
    publicContract: {
      title: "Public contract",
      breadcrumb: "Public contract",
      link: "public-contract",
    },
    chooseTariff: {
      title: "Submit your order",
      description: "Fill out the form and we will contact you to clarify the details",
      videoText: "View package overview",
      breadcrumb: "Tariff selection",
      link: "choose-tariff",
      tariffUrls: [
        "self_education",
        "self_education_plus",
        "team_support",
        "team_support_2",
        "team_support_3",
        "vip",
        "vip_2",
        "vip_3",
        "call_1"
      ],
      form: {
        title: "Tariff plans",
        tariffPlan: "Your plan",
        payment: {
          title: "Payment",
          options: ["Submit your order", "Buy now", "Buy in installments"],
          installmentPlan: [
            {
              title: "Payment in 2 stages",
              percent: 20
            },
            {
              title: "Payment in 3 stages",
              percent: 30
            },
          ]
        },
        payPartsInfo: 'You will receive instructions with detailed information on making payments in installments via Viber or by SMS.',
        plans: [
          {
            name: 'Choose a plan',
            value: 'default',
            price: 0
          },
          {
            name: 'Self Education',
            value: 'self',
            price: 239
          },
          {
            name: 'Self Education +',
            value: 'selfPlus',
            price: 599
          },
          {
            name: 'Team support',
            value: 'team',
            price: 1199
          },
          {
            name: 'VIP',
            value: 'vip',
            price: 2399
          },
        ],
        calc: {
          title: "Your order",
          tariff: "Plan",
          total: "Total cost",
          btnSubmit: ["Make reservation", "Pass to payment"]
        }
      },
      btn1: "More info",
      btn2: "Course program",
      table: {
        columnNames: [
          "Theory and practice",
          "Theoretical lessons",
          "Practical Lessons",
          "Group webinars on practicing arbitration skills",
          "Number of photos for working out tasks",
          "Number of photos to be reviewed by mentors",
          "Access to course materials",
          "Personal certificate at the end of the course",
          "Mentors' support",
          "Access to the general course chat",
          "Checking homework",
          "Detailed video analysis of all works",
          "Personal answers to questions on the platform",
          "Mentors' support",
          "Private chat with course speaker",
          "Portfolio",
          "Providing a photo for a portfolio",
          "Help in compiling a portfolio",
          "Personal consultation of the speaker on further development",
          "Bonus Lessons",
          "Step by step retouching with a speaker",
          "Selling retoucher portfolio",
          "Client search strategy"
        ],
        messages: [
          "1 year<br/>from date of purchase",
          "The service can be purchased during the course",
          "Upon successful completion of the exam",
          "Checking",
          "photos",
          "Only during midterm and final exams",
          "months",
          "with mentor's support",
          "The service can be purchased during the course",

          "Access for the best students<br/> (The best students are selected by mentors based on the results of homework)",
          "When paying in one payment",
          "of them are required",
          "with course speaker verification",
        ]
      }
    },
    studentsResults: {
      title: "Your results",
      breadcrumb: "Studying at Easy School",
      slogan: "Your results after<br/> <span className=\"bright\">2 weeks</span> of training with us",
      btn1: "Main",
      btn2: "Students portfolio",
    },
    studentsPortfolio: {
      title: "Students portfolio",
      breadcrumb: "Studying at Easy School",
      btn1: "Main",
      btn2: "Students results",
    }
  }
};
