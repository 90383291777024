import React, { useEffect, useState } from 'react';
import './students-portfolio.scss';
import {useNavigate} from "react-router-dom";
import Button from "../button";
import PortfolioStudents from "../portfolio-students";
import {HashLink} from "react-router-hash-link";
import axios from 'axios';

const StudentsPortfolio = (props: any) => {
  let navigate = useNavigate();

  const showResults = () => {
    navigate('/students-results');
  }

  const goHome = () => {
    if (props.fromWhere === 'retouchClub') {
      return navigate('/#qualityRetouch');
    }
    return navigate('/#main');
  }

  const BtnHome = () => {
    if (props.fromWhere === 'retouchClub') {
      return (
        <HashLink to="/#qualityRetouch"
                  scroll={(el) => el.scrollIntoView({behavior: 'smooth', block: 'end'})}>
          <Button
            text={props.data.studentsPortfolio.btn1}
            clazz='students-portfolio'
            onHandleClick={goHome}
          />
        </HashLink>
      )
    }
    return (
      <HashLink to="/#main"
                scroll={(el) => el.scrollIntoView({behavior: 'smooth', block: 'start'})}>
      <Button
        text={props.data.studentsPortfolio.btn1}
        clazz='students-portfolio'
        onHandleClick={goHome}
      />
      </HashLink>
    )
  }

  const [token, setToken] = useState('');
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const apiUrl = 'https://easyschool.retouchclub.com/insta-server';
    axios.get(apiUrl).then((res) => {
      const token = res.data;
      setToken(token);
      setLoading(false);
    });
  }, [setToken]);

  const ShowPortfolio = () => isLoading ?
                        <div className="loadingio-spinner-dual-ring-wfiizqs7edj">
                          <div className="ldio-6lbj7tk3vqw">
                            <div></div>
                            <div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      : <PortfolioStudents
                        isTitle={false}
                        data={props.data}
                        token={token}
                        theme={props.theme}
                      />;

  return (
    <div id="students-portfolio-page" className="students-portfolio-container">
      <div className="breadcrumbs">
        <a
          className={`bread-link ${props.theme}`}
          onClick={goHome}
        >{props.data.basic.main}
        </a> /
        <span>{props.data.studentsPortfolio.breadcrumb}</span> /
        <span>{props.data.studentsPortfolio.title}</span>
      </div>
      <section className='portfolio-container'>
        <div className={`portfolio-title ${props.theme}`}>
          {props.data.studentsPortfolio.title}
        </div>
        <ShowPortfolio />
        <div className="btns-container">
          <BtnHome/>
          <Button
            text={props.data.studentsPortfolio.btn2}
            clazz='students-portfolio'
            onHandleClick={showResults}
          />
        </div>
      </section>
    </div>
  );
}

export default StudentsPortfolio;