import React from "react";
import './privacy-policy.scss';
import {useNavigate} from "react-router-dom";

const PrivacyPolicy = (props:any) => {
  const {data, lang, theme} = props;

  let navigate = useNavigate();
  const goHome = () => {
    navigate('/#main');
  }

  return (
    <div className="page-container">
      <div className="breadcrumbs">
        <a
          onClick={goHome}
          className={`bread-link ${theme}`}
        >{data.basic.main}</a> /
        <span>{data.privacyPolicy.breadcrumb}</span>
      </div>
      <div className={`page-title ${theme}`}>
        {data.privacyPolicy.title}
      </div>
      {lang === 'ru'
        ?
        <div className={`page-text ${theme}`}>
          Ваша конфиденциальность очень важна для нас.<br/><br/>
          Ознакомьтесь с условиями конфиденциальности. Мы хотим, чтобы Ваша работа в Интернет по возможности
          была максимально приятной и полезной, и Вы совершенно спокойно использовали широчайший спектр информации,
          инструментов и возможностей, которые предлагает Интернет.<br/><br/>
          Личная информация клиентов, собранная при регистрации (или в любое другое время) преимущественно
          используется для подготовки Продуктов или Услуг в соответствии с Вашими потребностями.
          Ваша информация не будет передана или продана третьим сторонам.<br/><br/>
          Какие данные собираются на сайте: При добровольной регистрации на получение рассылки Вы отправляете свое Имя,
          Номер телефона и E-mail через форму регистрации.<br/><br/>
          С какой целью собираются эти данные:<br/><br/>
          Имя используется для обращения лично к Вам, номер телефона для возможности позвонить Вам, а Ваш e-mail для
          отправки писем рассылок, новостей, полезных материалов, специальных предложений. Ваши имя, номер телефона
          и e-mail не передаются третьим лицам, ни при каких условиях кроме случаев, связанных с исполнением требований
          законодательства. Ваше имя, номер телефона и e-mail на защищенных серверах и используются
          в соответствии с их политикой конфиденциальности. Вы можете отказаться от получения писем
          рассылки и удалить из базы данных свои контактные данные в любой момент, кликнув на ссылку для отписки,
          присутствующую в каждом письме.<br/><br/>
          Как эти данные используются:<br/><br/>
          На сайте используются куки (Cookies) и данные о посетителях сервиса Google Analytics.
          При помощи этих данных собирается информация о действиях посетителей на сайте с целью улучшения его
          содержания, улучшения функциональных возможностей сайта и, как следствие, создания качественного контента и
          предложений для посетителей. Вы можете в любой момент изменить настройки своего браузера так, чтобы браузер
          блокировал все файлы cookie или оповещал об отправке этих файлов. Учтите при этом, что некоторые функции и
          сервисы не смогут работать должным образом.<br/><br/>
          Как эти данные защищаются:<br/><br/>
          Для защиты Вашей личной информации мы используем разнообразные административные, управленческие и технические
          меры безопасности. Наша Компания придерживается различных международных стандартов контроля, направленных на
          операции с личной информацией, которые включают определенные меры контроля по защите информации, собранной в
          Интернет. Наших сотрудников обучают понимать и выполнять эти меры контроля, они ознакомлены с нашим
          Уведомлением о конфиденциальности, нормами и инструкциями. Тем не менее, несмотря на то, что мы стремимся
          обезопасить Вашу личную информацию, Вы тоже должны принимать меры, чтобы защитить ее Мы настоятельно
          рекомендуем Вам принимать все возможные меры предосторожности во время пребывания в Интернете.<br/><br/>
          Организованные нами услуги и веб-сайты предусматривают меры по защите от утечки, несанкционированного
          использования и изменения информации, которую мы контролируем. Несмотря на то, что мы делаем все возможное,
          чтобы обеспечить целостность и безопасность своей сети и систем, мы не можем гарантировать, что наши меры
          безопасности предотвратят незаконный доступ к этой информации хакеров сторонних организаций. В случае
          изменения данной политики конфиденциальности вы сможете прочитать об этих изменениях на этой странице или,
          в особых случаях, получить уведомление на свой email.<br/><br/>
          Интенсив по ретуши от Retouch Club проводится в форме доступа к чат-боту на платформе Телеграм, где
          содержатся обучающие материалы, ссылки на дополнительные источники и кнопка для старта персонального
          онлайн-чата с кураторами, где пользователи могут задать возникающие вопросы и загрузить готовые задания
          для проверки.<br/><br/>
          Внесение оплаты для получения доступа к боту производится единоразово, после чего пользователь получает
          доступ к учебным материалам и онлайн-чату с кураторами.<br/><br/>
          Телеграм-бот запрограммирован на отправку всех учебных материалов сразу же после поступления оплаты.<br/><br/>

          Доступ к материалам интенсива не ограничен, материалы доступны для скачивания и хранятся в кэш-памяти
          платформы Телеграм. Период действия онлайн-чата с кураторами — 10 дней, по истечении этого срока
          в чат-бот не будут поступать новые обучающие материалы.
        </div>
        :
        <div className={`page-text ${theme}`}>
          Your privacy is very important to us.<br/><br/>
          increased confidentiality. We want your online experience to be as good as possible.
          was as pleasant and useful as possible, and you quite unexpectedly used the widest range of information,
          the tools and opportunities that the Internet offers.<br/><br/>
          Personal information of customers collected at registration (or at any other time) is exclusively
          used to prepare food or products as described.
          Your information will not be shared or sold to an equal party.<br/><br/>
          What data is collected on the site: When you voluntarily register to receive the newsletter, you send your Name,
          Phone number and E-mail through the registration form.<br/><br/>
          For what purpose is this data collected:<br/><br/>
          The name is used to address you personally, the phone number is used to call you, and your e-mail is used to
          sending newsletters, news, useful materials, special offers. Your name, phone number
          and e-mail are not transferred to third parties, under no circumstances, except in cases related to the fulfillment of requirements
          legislation. Your name, phone number and e-mail are on secure servers and are used
          in accordance with their privacy policy. You can opt out of receiving emails
          mailing lists and delete your contact details from the database at any time by clicking on the unsubscribe link,
          present in every letter.<br/><br/>
          How this data is used:<br/><br/>
          This site uses cookies and data about Google Analytics visitors.
          With the help of this data, information is collected about the actions of visitors on the site in order to improve it.
          content, improving the functionality of the site and, as a result, creating high-quality content and
          suggestions for visitors. You can change your browser settings at any time so that your browser
          block all cookies or notify you when these files are being sent. Please note that some features and
          services will not be able to work properly.<br/><br/>
          How this data is protected:<br/><br/>
          To protect your personal information, we use a variety of administrative, managerial and technical
          security measures. Our Company adheres to various international control standards aimed at
          transactions with personal information that include certain controls to protect information collected in
          Internet. Our employees are trained to understand and implement these controls, they are familiar with our
          Privacy Notice, Rules and Guidelines. However, even though we strive
          to protect your personal information, you must also take steps to protect it We strongly
          We recommend that you take all possible precautions while you are on the Internet.<br/><br/>
          The services and websites organized by us include measures to protect against leakage, unauthorized
          use and change the information we control. Even though we do our best
          to ensure the integrity and security of our network and systems, we cannot guarantee that our measures
          security will prevent illegal access to this information by third party hackers. When
          changes to this privacy policy, you will be able to read about those changes on this page or,
          in special cases, be notified by email.<br/><br/>
          The retouching intensive from Retouch Club is held in the form of access to the chat bot on the Telegram platform, where
          contains training materials, links to additional sources and a button to start a personal
          online chat with curators, where users can ask questions and download ready-made assignments
          to check.<br/><br/>
          Payment for access to the bot is made one-time, after which the user receives
          access to study materials and online chat with curators.<br/><br/>
          The Telegram bot is programmed to send all educational materials immediately after payment is received.<br/><br/>
          Access to intensive materials is not limited, materials are available for download and are stored in the cache
          Telegram platforms. The period of validity of the online chat with the curators is 10 days, after this period
          the chatbot will not receive new training materials.
        </div>
      }
    </div>
  );
}

export default PrivacyPolicy;