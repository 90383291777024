import React, {useEffect, useState} from "react";
import './students-results.scss';
import {useNavigate} from "react-router-dom";
import img1Start from "../../assets/img/results/1start.webp";
import img1End from "../../assets/img/results/1end.webp";
import {getUniqueKey} from "../../utils/utils";
import {ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage} from "react-compare-slider";
import Button from "../button";
import { styleFitContainer } from 'react-compare-slider';
import Parser from "html-react-parser";

const imgArr = [[img1Start, img1End]];

const StudentsResults = (props: any) => {
  const initialImgState = {
    imgActive: 1,
    isMove: false,
    moveDirection: 'left'
  }

  const [imgState, setImgState] = useState(initialImgState);
  const imgCount = 1;
  const arrowStatusLeft = (imgState.imgActive !== 1 && !imgState.isMove) ? 'active' : '';
  const arrowStatusRight = (imgState.imgActive < imgCount && !imgState.isMove) ? 'active' : '';
  const onImgMove = (direction: string) => {
    const current = (direction === 'left') ? imgState.imgActive - 1 : imgState.imgActive + 1;
    const newImgState = {
      imgActive: current,
      isMove: true,
      moveDirection: direction
    }
    setImgState(newImgState);
  }
  const imgMoveFinish = () => {
    setImgState({...imgState, isMove: false});
  }

  let navigate = useNavigate();

  const showStudentsPortfolio = () => {
    navigate('../students-portfolio');
  }

  const goHome = () => {
    navigate('/#qualityRetouch');
  }

  const imgs = imgArr.map((item, index) => {
    const current = imgState.imgActive - 1;
    const getClassPos = () => {
      if (index === current) return 'active';
      if (index === current - 1) return 'prev';
      if (index === current + 1) return 'next';
      return '';
    }
    const getClassMove = () => imgState.isMove ? imgState.moveDirection : '';
    const clazz = getClassPos() + ' ' + getClassMove();

    return (
      <div
        className={`easy-result ${clazz}`}
        onAnimationEnd={imgMoveFinish}
        key={getUniqueKey()}
      >
        <ReactCompareSlider
          boundsPadding={0}
          handle={
            <ReactCompareSliderHandle
              buttonStyle={{
                backdropFilter: undefined,
                background: '#fff',
                border: 0,
                color: '#FB0552',
              }} />
          }
          itemOne={<ReactCompareSliderImage
            alt={`before ${index}`}
            src={item[0]}
            style={{
              ...styleFitContainer({
                                  objectFit: 'contain',
                                  objectPosition: 'center',
                                })
            }}
          />}
          itemTwo={<ReactCompareSliderImage
            alt={`after ${index}`}
            src={item[1]}
            style={{
              ...styleFitContainer({
                objectFit: 'contain',
                objectPosition: 'center',
              })
            }}
          />}
          position={50}
          style={{
            height: '80vh',
          }}

        />
      </div>
    )
  });

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (imgState.imgActive === imgCount) {
  //       setImgState({
  //         imgActive: 1,
  //         isMove: true,
  //         moveDirection: 'right'
  //       });
  //     }
  //     else onImgMove('right')
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, [imgState.isMove])

  const [docWidth, setDocWidth] = useState(document.documentElement.clientWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setDocWidth(document.documentElement.clientWidth);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setDocWidth(docWidth);
      });
    }
  }, [])

  let resultsBtnsSmall = docWidth < 1226 ? '' : 'hidden';
  let resultsBtnsBig = docWidth > 1226 ? '' : 'hidden';

  return (
    <div id="students-results" className="students-results-container">
      <div className="breadcrumbs">
        <a
          className={`bread-link ${props.theme}`}
          onClick={goHome}
        >{props.data.basic.main}</a> /
        <span> {props.data.studentsResults.breadcrumb}</span> /
        <span> {props.data.studentsResults.title}</span>
      </div>
      <section className='results-container'>
        <div className="results-container-left">
          <div className={`results-title ${props.theme} `}>
            {Parser(props.data.studentsResults.slogan)}
          </div>
          <div className={`btn-page-container ${resultsBtnsBig}`}>
            <div className="btns-container">
              <Button
                text={props.data.studentsResults.btn1}
                clazz='students-results'
                onHandleClick={goHome}
              />
              <Button
                text={props.data.studentsResults.btn2}
                clazz='students-results'
                onHandleClick={showStudentsPortfolio}
              />
            </div>
          </div>
        </div>
        <div className="results-container-right">
          <div className="results-main">
            {imgs}
            <div className={`results-arrow-left ${arrowStatusLeft} ${props.theme}`} onClick={() => onImgMove('left')}>
            </div>
            <div className={`img-numbers ${props.theme}`}>
              <div className="current">
                {imgState.imgActive}
              </div>
              /{imgCount}
            </div>
            <div className={`results-arrow-right ${arrowStatusRight} ${props.theme}`} onClick={() => onImgMove('right')}>
            </div>
          </div>
        </div>
        <div className={`btn-page-container ${resultsBtnsSmall}`}>
          <div className="btns-container">
            <Button
              text={props.data.studentsResults.btn1}
              clazz='students-results'
              onHandleClick={goHome}
            />
            <Button
              text={props.data.studentsResults.btn2}
              clazz='students-results'
              onHandleClick={showStudentsPortfolio}
            />
          </div>
        </div>
      </section>

    </div>
  );
}

export default StudentsResults;