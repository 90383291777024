import React, { useEffect, useState } from 'react';
import './portfolio-students.scss';
import {PortfolioPropsType} from "../../types";
import axios from 'axios';
// @ts-ignore
import InstagramFeed  from 'react-ig-feed';

const PortfolioStudents = (props: PortfolioPropsType) => {
  const title = props.isTitle? props.data.title : '';
  const token = props.token;

  // const [token, setToken] = useState('');
  // const [isLoading, setLoading] = useState(true);
  // useEffect(() => {
  //   console.log('token: ', token);
  //   if (!token){
  //     const apiUrl = 'https://easyschool.retouchclub.com/insta-server';
  //     axios.get(apiUrl).then((res) => {
  //       const token = res.data;
  //       setToken(token);
  //       setLoading(false);
  //     });
  //   }
  // }, [setToken]);
  // if (isLoading) {
  //   return  <div className="loadingio-spinner-dual-ring-wfiizqs7edj">
  //     <div className="ldio-6lbj7tk3vqw">
  //       <div></div>
  //       <div>
  //         <div></div>
  //       </div>
  //     </div>
  //   </div>;
  // }

  return (
    <div className="students-portfolio-page">
      <div className="portfolio-title">
        {title}
      </div>
      <div className='gallery'>
        <InstagramFeed token={token} counter="12"/>
      </div>
    </div>

  )
}

export default PortfolioStudents;