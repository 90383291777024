import React from 'react';
import './prefooter.scss';
import {PrefooterPropsType} from "../../types";

const Prefooter = (props: PrefooterPropsType) => {
  const {basic, prefooter} = props.data;
  const theme = props.theme;
  return (

    <section id="prefooter" className="prefooter">
      <div className={`prefooter-container ${theme}`}>
        <section className="easy-subscribers">
          <h2>Easy school</h2>
          <div className="easy-subscribers-icons">
            <a href={basic.socialLinks.instagram} target="_blank" rel="noreferrer" className={`prefooter-instagram ${theme}`}>
            </a>
            <a href={basic.socialLinks.telegram} target="_blank" rel="noreferrer" className={`prefooter-telegram ${theme}`}>
            </a>
            {/*<div className="prefooter-youtube">*/}
            {/*</div>*/}
            <a href={basic.socialLinks.youtube} target="_blank" rel="noreferrer" className={`prefooter-youtube ${theme}`}>
            </a>
          </div>
        </section>
        <section className="easy-contacts">
          <div className={`easy-contacts-title ${theme}`}>
            {prefooter.contacts}
          </div>
          <div className="easy-contacts-phone">
            {/*<a href="tel: +375296036392">*/}
            {/*  +375 29 603 63 92*/}
            {/*</a>*/}
          </div>
          <div className={`easy-contacts-email ${theme}`}>
            <a href="mailto: mail@retouchclub.com">
              mail@retouchclub.com
            </a>
          </div>
        </section>
      </div>
    </section>
  )
}
export default Prefooter;
