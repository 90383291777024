import React, { useEffect } from 'react';
import './footer.scss';
import { FooterPropsType } from '../../types';
import { useLocation } from 'react-router-dom';
import Parser from 'html-react-parser';

const Footer = (props: FooterPropsType) => {
  const { data, theme } = props;
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash;
    const el = hash && document.getElementById(hash.substr(1));
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    // if (el && hash === '#section3') el.scrollIntoView({behavior: "smooth", block: 'end' })
    // if (el && hash === '#qualityRetouch') el.scrollIntoView({behavior: "smooth", block: 'end' })

    if (el === null) {
      setTimeout(() => {
        const hash = location.hash;
        const el = hash && document.getElementById(hash.substr(1));
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }

      }, 2000);
    }

  }, [location.hash]);

  const links = data.basic.menu.menuBottom.map((item) => {
    return (
      <a
        href={item.link}
        className={item.class}
        key={item.class}
        target="_blank"
        rel="noreferrer"
      >
        {item.title}
      </a>
    );
  });

  return (
    <section id="footer" className={`footer ${theme} `}>
      <div className="footer-left">
        {Parser(data.footer.rights)}
      </div>
      <div className="footer-right">
        {links}
      </div>
    </section>
  );
};

export default Footer;