import React from 'react';
import {ButtonType} from "../../types";
import './button.scss';

const Button = (props: ButtonType) => {
  const {text, clazz, formData, maskLength, url, onHandleClick} = props;
  if (formData && maskLength) {
    const {name, phone, politics} = formData;
    const formDisabled = !((name.length > 1) && (phone.length === maskLength) && politics);
    return (
      <button
        type="submit"
        className={`easy-btn ${clazz}-btn`}
        disabled={formDisabled}>
        {text}
      </button>
    );
  }
  if (onHandleClick) {
    if (url) {
      // @ts-ignore
      return (
        <button
          type="button"
          className={`easy-btn ${clazz}-btn`}
          onClick={() => {
            onHandleClick(url)
          }}>
          {text}
        </button>
      );
    }

    return (
      <button
        type="button"
        className={`easy-btn ${clazz}-btn`}
        onClick={onHandleClick}>
        {text}
      </button>
    );
  }
  return (
    <button
      type="submit"
      className={`easy-btn ${clazz}-btn`}>
      {text}
    </button>
  );
}

export default Button;